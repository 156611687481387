import React, {useEffect} from 'react';
import { Button, Card, CardBody, Col, Container, Row } from 'reactstrap';

// Import Images
import offlineImg from "../../assets/images/auth-offline.gif";
import {Link} from "react-router-dom";
import {Utils} from "custom";

const Offlinepage = () => {

    useEffect(() => {
        checkConnection();
    }, []);

    const checkConnection = () => {
        Utils.checkConnection().then(() => {
            window.location.replace('/');
        }).catch(() => {

        })
    }

    document.title = "Server Offline | " + process.env.REACT_APP_NAME;
    return (
        <React.Fragment>
            <div className="auth-page-wrapper auth-bg-cover py-5 d-flex justify-content-center align-items-center min-vh-100">
                <div className="bg-overlay"></div>
                <div className="auth-page-content overflow-hidden pt-lg-5">
                    <Container>
                        <Row className="justify-content-center">
                            <Col xl={5}>
                                <Card className="overflow-hidden">
                                    <CardBody className="p-4">
                                        <div className="text-center">
                                            <h3 className="mt-4 fw-semibold">AGGIORNAMENTO DELLA PIATTAFORMA</h3>
                                            <p className="text-muted mb-4 fs-14">Siamo spiacenti, il server al momento è in manutenzione, ti preghiamo di riprovare più tardi</p>
                                            <a href="javascript:void(0);" onClick={() => checkConnection()} className="btn btn-success"><i className="mdi mdi-home me-1"></i>Riprova</a>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
        </React.Fragment>
    );
};

export default Offlinepage;
