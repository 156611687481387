let getDaysAvailable = function(service_id) {
    return new Promise(function(resolve, reject) {
        const hash = localStorage.getItem('hash');

        fetch(global.route.getAvailableDays + "?&service_id=" + service_id, {
            headers: {
                'x-access-token': hash
            },
            method: 'GET'
        })
            .then((response) => (response.status !== 500) ? response.json() : reject())
            .then((responseJson) => {
                global.checkAuth(responseJson.status);
                console.log('available days list', responseJson);
                if (responseJson.status === "LIST_AVAILABLE_DAYS")
                    resolve(responseJson);
                else
                    reject(responseJson);
            })
            .catch((e) => {
                console.error(e.message);
                reject('APP_ERROR');
            });
    });
};

let getSlotsAvailable = function(day, service_id) {
    return new Promise(function(resolve, reject) {
        const hash = localStorage.getItem('hash');

        fetch(global.route.getAvailableSlot + "?day=" + day + "&service_id=" + service_id, {
            headers: {
                'x-access-token': hash
            },
            method: 'GET'
        })
            .then((response) => (response.status !== 500) ? response.json() : reject())
            .then((responseJson) => {
                global.checkAuth(responseJson.status);
                console.log('available slot list', responseJson);
                if (responseJson.status === "LIST_AVAILABLE_SLOTS")
                    resolve(responseJson.slots);
                else
                    reject(responseJson);
            })
            .catch((e) => {
                console.error(e.message);
                reject('APP_ERROR');
            });
    });
};

let list = function(date_from, date_to) {
    return new Promise(function(resolve, reject) {
        const hash = localStorage.getItem('hash');

        fetch(global.route.booking + "?date_from=" + date_from + "&date_to=" + date_to, {
            headers: {
                'x-access-token': hash
            },
            method: 'GET'
        })
            .then((response) => (response.status !== 500) ? response.json() : reject())
            .then((responseJson) => {
                global.checkAuth(responseJson.status);
                console.log('booking list', responseJson);
                if (responseJson.status === "LIST_BOOKINGS")
                    resolve(responseJson.list);
                else
                    reject(responseJson);
            })
            .catch((e) => {
                console.error(e.message);
                reject('APP_ERROR');
            });
    });
};

let detail = function(id) {
    return new Promise(function(resolve, reject) {
        const hash = localStorage.getItem('hash');

        fetch(global.route.bookingDetail + "/" + id, {
            headers: {
                'x-access-token': hash
            },
            method: 'GET'
        })
            .then((response) => (response.status !== 500) ? response.json() : reject())
            .then((responseJson) => {
                global.checkAuth(responseJson.status);
                console.log('booking detail', responseJson);
                if (responseJson.status === "DETAIL_BOOKING")
                    resolve(responseJson.booking);
                else
                    reject(responseJson);
            })
            .catch((e) => {
                console.error(e.message);
                reject('APP_ERROR');
            });
    });
};

let add = function(body) {
    return new Promise(function(resolve, reject) {
        const hash = localStorage.getItem('hash');

        fetch(global.route.booking, {
            headers: {
                'x-access-token': hash,
                'Content-Type': 'application/json'
            },
            method: 'PUT',
            body: JSON.stringify(body)
        })
        .then((response) => (response.status !== 500) ? response.json() : reject())
        .then((responseJson) => {
            global.checkAuth(responseJson.status);
            console.log('booking create', responseJson);
            if (responseJson.status === "BOOKING_CREATED")
                resolve(responseJson);
            else
                reject(responseJson);
        })
        .catch((e) => {
            console.error(e.message);
            reject('APP_ERROR');
        });
    });
};

let edit = function(body) {
    return new Promise(function(resolve, reject) {
        const hash = localStorage.getItem('hash');

        fetch(global.route.booking, {
            headers: {
                'x-access-token': hash,
                'Content-Type': 'application/json'
            },
            method: 'POST',
            body: JSON.stringify(body)
        })
            .then((response) => (response.status !== 500) ? response.json() : reject())
            .then((responseJson) => {
                global.checkAuth(responseJson.status);
                console.log('booking edit', responseJson);
                if (responseJson.status === "BOOKING_UPDATED")
                    resolve(responseJson);
                else
                    reject(responseJson);
            })
            .catch((e) => {
                console.error(e.message);
                reject('APP_ERROR');
            });
    });
};

let remove = function(id) {
    return new Promise(function(resolve, reject) {
        const hash = localStorage.getItem('hash');

        fetch(global.route.booking, {
            headers: {
                'x-access-token': hash,
                'Content-Type': 'application/json'
            },
            method: 'DELETE',
            body: JSON.stringify({
                id: id
            })
        })
            .then((response) => (response.status !== 500) ? response.json() : reject())
            .then((responseJson) => {
                global.checkAuth(responseJson.status);
                console.log('booking delete', responseJson);
                if (responseJson.status === "BOOKING_DELETED")
                    resolve(responseJson);
                else
                    reject(responseJson);
            })
            .catch((e) => {
                console.error(e.message);
                reject('APP_ERROR');
            });
    });
};

const BookingAPI = {
    getDaysAvailable: getDaysAvailable,
    getSlotsAvailable: getSlotsAvailable,
    list: list,
    detail: detail,
    add: add,
    edit: edit,
    remove: remove,
};

module.exports = BookingAPI;
