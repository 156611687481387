import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    Col,
    Container,
    Form, FormFeedback,
    Input,
    Label,
    Nav,
    NavItem,
    NavLink,
    Row,
    TabContent,
    TabPane
} from 'reactstrap';
import classnames from "classnames";
import Flatpickr from "react-flatpickr";
import {Loading, Utils} from "custom";
import {BookingAPI, UserAPI} from "api";

//import images
// import progileBg from '../../assets/images/users/';
// import avatar1 from '../../assets/images/';

const TabProfile = ({user}) => {
    const [form, setForm] = useState(user);
    const [errors, setErrors] = useState(false);
    const [loading, setLoading] = useState(false);

    const changeForm = (key, value) => {

        if (key === "cell") {
            if (value[0] === "+") {
                value = "+" + value.slice(1).replace(/\D/g, "");
            } else {
                value = value.replace(/\D/g, "");
            }

            if (value.length >= 3 && value[0] !== "+") {
                value = "+39" + value;
            }
        }


        setForm(prevState => ({
            ...prevState,
            [key]: value
        }));
    };

    const saveProfile = () => {
        const newErrors = {};

        if (!form.firstname) newErrors.firstname = "Il campo nome è obbligatorio";
        if (!form.lastname) newErrors.lastname = "Il campo cognome è obbligatorio";
        if (!form.email) newErrors.email = "Il campo email è obbligatorio";
        if (!form.cell) newErrors.cell = "Il campo cellulare è obbligatorio";
        if (form.cell && !Utils.isValidCell(form.cell)) newErrors.cell = "Il cellulare non è valido";
        if (form.email && !Utils.isValidEmail(form.email)) newErrors.email = "La mail non è valida";

        setErrors(newErrors);

        if (Object.keys(newErrors).length === 0) {
            setLoading(true);

            UserAPI.editProfile(form).then(() => {
                    setLoading(false);
                    global.SweetAlert.fire({
                        title: 'Profilo modificato',
                        text: 'Il profilo è stato modificato correttamente.',
                        icon: 'success',
                        allowOutsideClick: false,
                        customClass: { confirmButton: 'btn btn-primary' },
                        confirmButtonText: 'Chiudi',
                        buttonsStyling: false
                    });
                })
                .catch(err => {
                    setLoading(false);
                    global.SweetAlert.fire({
                        title: 'Errore',
                        text: 'Si è verificato un errore durante la modifica del profilo',
                        icon: 'error',
                        customClass: { confirmButton: 'btn btn-primary' }
                    });
                });
        }
    }

    return (
        <React.Fragment>
            {(loading) ? <Loading /> : <Form>
                <Row>
                    <Col lg={6}>
                        <div className="mb-3">
                            <Label className="form-label">Nome</Label>
                            <Input type="text"
                                   className="form-control"
                                   placeholder="Inserisci il nome"
                                   value={form.firstname}
                                   invalid={Boolean(errors.firstname)}
                                   onChange={(e) => changeForm('firstname', e.target.value)}
                            />
                            <FormFeedback>{errors.firstname}</FormFeedback>
                        </div>
                    </Col>
                    <Col lg={6}>
                        <div className="mb-3">
                            <Label className="form-label">Cognome</Label>
                            <Input type="text"
                                   className="form-control"
                                   placeholder="Inserisci il cognome"
                                   value={form.lastname}
                                   invalid={Boolean(errors.lastname)}
                                   onChange={(e) => changeForm('lastname', e.target.value)}/>
                            <FormFeedback>{errors.lastname}</FormFeedback>
                        </div>
                    </Col>
                    <Col lg={6}>
                        <div className="mb-3">
                            <Label className="form-label">Cellulare</Label>
                            <Input type="text"
                                   className="form-control"
                                   placeholder="Inserisci il cellulare"
                                   value={form.cell}
                                   invalid={Boolean(errors.cell)}
                                   onChange={(e) => changeForm('cell', e.target.value)}/>
                            <FormFeedback>{errors.cell}</FormFeedback>
                        </div>
                    </Col>
                    <Col lg={6}>
                        <div className="mb-3">
                            <Label className="form-label">Email</Label>
                            <Input type="email"
                                   className="form-control"
                                   placeholder="Inserisci l'email"
                                   value={form.email}
                                   invalid={Boolean(errors.email)}
                                   onChange={(e) => changeForm('email', e.target.value)}/>
                            <FormFeedback>{errors.email}</FormFeedback>
                        </div>
                    </Col>
                    <Col lg={12}>
                        <div className="hstack gap-2 justify-content-end">
                            <Button type="button" color="success" size="sm" onClick={saveProfile}>Aggiorna Profilo</Button>
                        </div>
                    </Col>
                </Row>
            </Form>}
        </React.Fragment>
    );
};

export default TabProfile;
