import React, { Component } from 'react';
import {Alert, Badge, Col, Container, Row} from 'reactstrap';
import BreadCrumb from '../../Components/Common/BreadCrumb';
import {withRouter, Link} from "react-router-dom";
import { Grid, _ } from 'gridjs-react';

import {GestCompany, DeleteCompany, Loading} from "custom";

import { AdminAPI, UserAPI, CompanyAPI } from "api";


class CompanyTable extends Component {

    constructor(props) {
        super(props);
        this.state = {
            companies: [],
            loading: true,
            error: false
        };
    }

    componentDidMount() {
        this.getCompanies();
    }

    getCompanies() {
        CompanyAPI.list().then((companies) => {
            this.setState({loading: false, error: false, companies: companies});
        }).catch(() => {
            this.setState({error: true});
        })
    }

    resendEmailSignup(id) {
        const self = this;
        global.SweetAlert.fire({
            title: 'Vuoi reinviare la mail di registrazione?',
            text: "Stai per reinviare una mail all'utente con il link per la registrazione, vuoi procedere?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Si, conferma',
            cancelButtonText: 'Annulla',
            customClass: {
                confirmButton: 'btn btn-primary',
                cancelButton: 'btn btn-outline-danger ms-1'
            },
            buttonsStyling: false,
            showLoaderOnConfirm: true,
            preConfirm: function() {
                return new Promise(function(resolve, reject) {
                    UserAPI.resendSignup(id).then(() => {
                        resolve();
                    }).catch(() => {
                        global.SweetAlert.fire({
                            icon: 'error',
                            title: 'Si è verificato un errore :(',
                            text: 'Purtroppo qualcosa è andato storto e non è stato possibile inviare nuovamente il codice di registrazione. Riprova più tardi.',
                            customClass: {
                                confirmButton: 'btn btn-success'
                            }
                        });
                        reject()
                    })
                });
            },
        }).then(function (result) {
            if (result.value) {
                global.SweetAlert.fire({
                    icon: 'success',
                    title: 'Email registrazione inviata!',
                    text: 'È stata inviata la mail di registrazione all\'utente.',
                    customClass: {
                        confirmButton: 'btn btn-success'
                    }
                });
                self.getAdministrators();
            }
        })
    }


    render() {
        const self = this;

        if (this.state.error) {
            return <React.Fragment>
                <Alert color="danger" >Si è verificato un errore durante il recupero della lista aziende.</Alert>
            </React.Fragment>
        }

        return (
            <React.Fragment>
                <div className="flex-shrink-0" style={{margin: 10}}>
                    <GestCompany onResolve={this.getCompanies.bind(this)}/>
                </div>
                {(this.state.loading) ? <Loading /> :
                <Grid
                    data={this.state.companies}
                    columns={[
                        {
                            id: 'id',
                            name: '#',
                            formatter: (cell, row) => _(
                                <span className="text-nowrap">{cell}</span>
                            ),
                            width: "50px"
                        },
                        {
                            id: 'name',
                            name: 'Nominativo',
                            formatter: (cell, row) => _(<span className="text-nowrap"><b>{cell}</b></span>)
                        },
                        {
                            id: 'time_zone',
                            name: 'Fuso Orario',
                            formatter: (cell, row) => _(<span className="text-nowrap">{cell}</span>)
                        },
                        {
                            id: 'active',
                            name: 'Stato',
                            formatter: (cell, row) => _(<span className="text-nowrap">
                                {(!row.cells[4].data) ? <Badge color="warning" className="cursor-pointer" onClick={() => this.resendEmailSignup(row.cells[0].data)}>In attesa registrazione</Badge> : ((cell) ? <Badge color="success" >Attivo</Badge> : <Badge color="danger" >Non Attivo</Badge>)}
                            </span>)
                        },
                        {
                            id: 'isRegistered',
                            hidden: true
                        },
                        {
                            id: 'id',
                            name: '',
                            sort: false,
                            formatter: (cell, row, i) => _(<ul
                                className="list-inline hstack gap-2 mb-0">
                                <GestCompany preset={this.state.companies.find((c) => c.id === cell)} onResolve={this.getCompanies.bind(this)} />
                                <DeleteCompany id={cell} onResolve={this.getCompanies.bind(this)} />
                            </ul>)
                        }
                    ]}
                    style={{table: {'white-space': 'nowrap'}}}
                    resizable={true}
                    search={true}
                    sort={true}
                    pagination={{ enabled: true, limit: 10}}
                    language={{
                        'search': {
                            'placeholder': '🔍 Cerca...'
                        },
                        'pagination': {
                            'previous': 'Indietro',
                            'next': 'Avanti',
                            'showing': 'Stai visualizzando da',
                            'results': 'Aziende',
                            'to': 'a',
                            'of': 'di',
                        },
                        'noRecordsFound': 'Nessuna azienda trovata',
                        'error': 'Si è verificato un errore non previsto',
                    }}
                />}
            </React.Fragment>
        );
    }
}

export default withRouter(CompanyTable);
