import React, {useEffect, useState} from "react";
import {useHistory} from "react-router-dom";

import {Utils} from "custom";

const Navdata = () => {
    let user = Utils.getUserData()

    let menuItems = [];

    let role = user.role;

    switch (role) {
        case "ADMIN":
            menuItems = [
                {
                    label: "Anagrafica",
                    isHeader: true,
                },
                {
                    id: "administrators",
                    label: "Amministratori",
                    icon: "mdi mdi-key",
                    link: "/admin",
                    disabled: false
                },
                {
                    id: "companies",
                    label: "Aziende",
                    icon: "mdi mdi-store",
                    link: "/company",
                    disabled: false
                }
            ];
            break;
        case "COMPANY":
            menuItems = [
                {
                    label: "Anagrafica",
                    isHeader: true,
                },
                {
                    id: "services",
                    label: "Servizi",
                    icon: "mdi mdi-toolbox-outline",
                    link: "/service",
                    disabled: false
                },
                {
                    id: "operators",
                    label: "Operatori",
                    icon: "mdi mdi-account-network",
                    link: "/operator",
                    disabled: false
                },
                {
                    id: "clients",
                    label: "Clienti",
                    icon: "mdi mdi-account-group",
                    link: "/client",
                    disabled: false
                },
                {
                    label: "Risultati",
                    isHeader: true,
                },
                {
                    id: "bookings",
                    label: "Prenotazioni",
                    icon: "mdi mdi-calendar-clock",
                    link: "/booking",
                    disabled: false
                },
                {
                    id: "availables",
                    label: "Disponibilità",
                    icon: "mdi mdi-calendar-multiple-check",
                    link: "/available",
                    disabled: false
                },
                {
                    label: "Impostazioni",
                    isHeader: true,
                },
                {
                    id: "place",
                    label: "Sedi",
                    icon: "mdi mdi-map-marker",
                    link: "/places",
                    disabled: false
                }
            ];
            break;
        case "CLIENT":
            menuItems = [];
            break;
        default:
            menuItems = [];
            break;
    }


    return <React.Fragment>{menuItems}</React.Fragment>;
};
export default Navdata;
