import React, { useCallback } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Alert } from 'reactstrap';
import {ServiceAPI} from 'api';
import { Utils } from 'custom';

const DeleteService = ({category, onResolve}) => {
    const showModal = useCallback(() => {

        global.SweetAlert.fire({
            title: 'Elimina servizio',
            text: 'Vuoi veramente eliminare il servizio?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Si, elimina',
            cancelButtonText: 'Annulla',
            customClass: {
                confirmButton: 'btn btn-primary',
                cancelButton: 'btn btn-outline-danger ms-1',
            },
            buttonsStyling: false,
            showLoaderOnConfirm: true,
            preConfirm: function () {
                return new Promise(function (resolve, reject) {
                    ServiceAPI.remove(category.id)
                        .then(() => {
                            resolve();
                        })
                        .catch(() => {
                            global.SweetAlert.fire({
                                icon: 'error',
                                title: 'Si è verificato un errore :(',
                                text: 'Purtroppo qualcosa è andato storto e non è stato possibile eliminare il servizio. Riprova più tardi.',
                                customClass: {
                                    confirmButton: 'btn btn-success',
                                },
                            });
                            reject();
                        });
                });
            },
        }).then(function (result) {
            if (result.value) {
                global.SweetAlert.fire({
                    icon: 'success',
                    title: 'Servizio eliminato!',
                    text: 'Servizio eliminato correttamente.',
                    customClass: {
                        confirmButton: 'btn btn-success',
                    },
                });
                onResolve();
            }
        });
    }, []);

    return (
        <>
            <li className="list-inline-item cursor-pointer" onClick={showModal}>
                <i className="ri-delete-bin-5-fill fs-20 text-danger"></i>
            </li>
        </>
    );
};

export default DeleteService;
