import React, { useState, useEffect, Fragment } from 'react';
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Row,
    Col,
    Label,
    FormFeedback,
    Input,
    Alert,
    Card,
    CardBody,
    CardTitle
} from 'reactstrap';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import moment from 'moment';
import { BookingAPI } from "api"; // importa BookingAPI come necessario
import {Loading, Utils} from "custom"; // importa Loading se disponibile
import 'react-calendar/dist/Calendar.css';

const EditBooking = ({ idBooking, onResolve, viewButton }) => {
    const [booking, setBooking] = useState(null);
    const [type, setType] = useState(null);
    const [selectedDay, setSelectedDay] = useState(null);
    const [form, setForm] = useState(null);
    const [loading, setLoading] = useState(true);
    const [loadingSlot, setLoadingSlot] = useState(true);
    const [editCheck, setEditCheck] = useState(false);
    const [error, setError] = useState(false);
    const [errors, setErrors] = useState({});
    const [modalOpen, setModalOpen] = useState(false);
    const [availableSlots, setAvailableSlots] = useState([]);
    const [availableDays, setAvailableDays] = useState([]);
    const [customFields, setCustomFields] = useState([]);
    const [customFieldsData, setCustomFieldsData] = useState([]);

    useEffect(() => {
        if (modalOpen) {
            BookingAPI.detail(idBooking)
                .then((bookingDetail) => {
                    setForm({
                        id: bookingDetail.id,
                        date_time: moment(bookingDetail.date_hour).format("YYYY-MM-DD HH:mm"),
                        client: bookingDetail.client,
                        custom_fields: bookingDetail.custom_fields,
                        place_id: bookingDetail.place.id
                    });
                    setCustomFieldsData(bookingDetail.client.custom_fields.map(field => ({
                        id: field.id,
                        value: field.value || ""
                    })));
                    setBooking(bookingDetail);
                    getAvailableDays(bookingDetail.service.id);
                    setLoading(false);
                })
                .catch(() => setError(true));
        }
    }, [modalOpen]);

    const toggleModal = () => {
        if (modalOpen) {
            resetForm();
        }
        setModalOpen(!modalOpen);
    };

    const resetForm = () => {
        setForm(null);
        setCustomFields([]);
        setCustomFieldsData([]);
        setSelectedDay(null);
        setErrors({});
        setType(null);
    };

    const getAvailableDays = async (id_service) => {
        setLoading(true);
        try {
            const res = await BookingAPI.getDaysAvailable(id_service);
            setAvailableDays(res.days.map(day => moment(day).toDate()));
            setCustomFields(res.service.custom_fields);

            if (res.service.custom_fields.length > 0) {
                setCustomFieldsData(res.service.custom_fields.map(c => ({ id: c.id, value: "" })));
            }
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    const getAvailableSlots = async (day) => {
        setLoadingSlot(true);
        try {
            const slots = await BookingAPI.getSlotsAvailable(moment(day).format("YYYY-MM-DD"), booking.service.id);
            setAvailableSlots(slots);
        } catch (error) {
            console.error(error);
        } finally {
            setLoadingSlot(false);
        }
    };

    const handleDaySelect = (day) => {
        setSelectedDay(day);
        getAvailableSlots(day);
    };

    const handleSlotSelect = (slot) => {
        changeForm('date_time', `${moment(selectedDay).format("YYYY-MM-DD")} ${slot}`);

        setBooking(prev => ({
            ...prev,
            date_hour: `${moment(selectedDay).format("YYYY-MM-DD")} ${slot}`
        }));

        setForm(prev => ({
            ...prev,
            date_time: `${moment(selectedDay).format("YYYY-MM-DD")} ${slot}`
        }));


        setSelectedDay(null);
        setType(null);
    };


    const changeForm = (key, value, section = 'booking') => {
        setEditCheck(true);
        setForm(prev => ({
            ...prev,
            [section]: {
                ...prev[section],
                [key]: value
            }
        }));
    };

    const handleCustomFieldChange = (id, value) => {
        setEditCheck(true);
        setCustomFieldsData(prevState =>
            prevState.map(field => (field.id === id ? { ...field, value } : field))
        );
        setForm(prev => ({
            ...prev,
            custom_fields: customFieldsData.map(field =>
                field.id === id ? { ...field, value } : field
            )
        }));
    };

    const saveBooking = () => {
        const newErrors = {};

        if (!form.client.name) newErrors.client_name = "Il campo nome è obbligatorio";
        if (form.client.cell && !Utils.isValidCell(form.client.cell)) newErrors.client_cell = "Cellulare non valido";
        if (form.client.email && !Utils.isValidEmail(form.client.email)) newErrors.client_email = "Email non valida";
        if (!form.date_time) newErrors.date_time = "L'orario è obbligatorio";

        setErrors(newErrors);

        if (Object.keys(newErrors).length === 0) {
            setLoading(true);

            const requestData = {
                id: idBooking,
                date_time: form.date_time,
                client: {
                    id: form.client.id || null,
                    name: form.client.name,
                    email: form.client.email || '',
                    cell: form.client.cell || ''
                },
                place_id: form.place_id,
                custom_fields: customFieldsData
            };

            BookingAPI.edit(requestData)
                .then(() => {
                    setLoading(false);
                    toggleModal();
                    onResolve();
                })
                .catch(() => setLoading(false));
        }
    };

    const tileDisabled = ({ date }) => {
        return !availableDays.find(d => moment(d).isSame(date, 'day'));
    };

    const renderCustomField = (field) => (
        <Col md="12" className="mt-3" key={field.id}>
            <Label>{field.name}</Label>
            <Input
                type={field.type.toLowerCase()}
                value={customFieldsData.find(c => c.id === field.id)?.value || ""}
                onChange={(e) => handleCustomFieldChange(field.id, e.target.value)}
            />
        </Col>
    );


    const saveClientChanges = () => {
        const newErrors = {};

        if (!form.client.name) newErrors.client_name = "Il campo nome è obbligatorio";
        if (form.client.cell && !Utils.isValidCell(form.client.cell)) newErrors.client_cell = "Cellulare non valido";
        if (form.client.email && !Utils.isValidEmail(form.client.email)) newErrors.client_email = "Email non valida";

        setErrors(newErrors);

        if (Object.keys(newErrors).length === 0) {
            setBooking(prev => ({
                ...prev,
                client: {
                    ...form.client
                }
            }));
            setType(null);
        }
    };


    const showForm = () => {
        if (type === "booking") {
            return !selectedDay ? (
                <div className="justify-content-center day-selection p-4 text-center">
                    <Button className="btn btn-sm btn-info" onClick={() => setType(null)}>
                        <i className="ri-arrow-left-circle-line fs-5 text-white-75 align-middle" /> Torna indietro
                    </Button>
                    <br />
                    <br />
                    <h5>Seleziona un Giorno Disponibile</h5>
                    <Row className="justify-content-center">
                        <Calendar
                            onChange={handleDaySelect}
                            tileDisabled={tileDisabled}
                            minDate={new Date()}
                        />
                    </Row>
                </div>
            ) : (
                <div className="slot-selection p-4 text-center">
                    <Button className="btn btn-sm btn-info" onClick={() => setSelectedDay(null)}>
                        <i className="ri-arrow-left-circle-line fs-5 text-white-75 align-middle" /> Torna indietro
                    </Button>
                    <br />
                    <br />
                    <h5>Seleziona uno Slot Orario</h5>
                    <Row>
                        {availableSlots.map(slot => (
                            <Col key={slot} md={4} className="mb-3">
                                <Button
                                    block
                                    style={{ backgroundColor: booking.service.color_scheme || '#5C68E2', color: 'white' }}
                                    onClick={() => handleSlotSelect(slot)}
                                >
                                    {slot}
                                </Button>
                            </Col>
                        ))}
                    </Row>
                </div>
            );
        }

        if (type === "client") {
            return (
                <div className="client-details p-4">
                    <Button className="btn btn-sm btn-info" onClick={() => setType(null)}>
                        <i className="ri-arrow-left-circle-line fs-5 text-white-75 align-middle" /> Torna indietro
                    </Button>
                    <br /><br />
                    <h5>Modifica i Dati del Cliente</h5>
                    <Row>
                        <Col md="6">
                            <Label>Nome*</Label>
                            <Input
                                type="text"
                                value={form.client.name}
                                invalid={Boolean(errors.client_name)}
                                onChange={(e) => changeForm('name', e.target.value, 'client')}
                            />
                            <FormFeedback>{errors.client_name}</FormFeedback>
                        </Col>
                        <Col md="6">
                            <Label>Cellulare</Label>
                            <Input
                                type="text"
                                value={form.client.cell}
                                invalid={Boolean(errors.client_cell)}
                                onChange={(e) => changeForm('cell', e.target.value, 'client')}
                            />
                            <FormFeedback>{errors.client_cell}</FormFeedback>
                        </Col>
                        <Col md="12" className="mt-3">
                            <Label>Email</Label>
                            <Input
                                type="email"
                                value={form.client.email}
                                invalid={Boolean(errors.client_email)}
                                onChange={(e) => changeForm('email', e.target.value, 'client')}
                            />
                            <FormFeedback>{errors.client_email}</FormFeedback>
                        </Col>
                    </Row>
                    <br />
                    {customFields && customFields.map(renderCustomField)}
                    <br />
                    <Button color="success" size="sm" onClick={saveClientChanges}>
                        Salva Modifiche
                    </Button>
                </div>
            );
        }


        return null;
    };

    return (
        <Fragment>
            {viewButton ? (
                <Button size="sm" color="primary" onClick={toggleModal} className="m-2">
                    <i className="mdi mdi-pencil" /> Modifica Prenotazione
                </Button>
            ) : (
                <li className="list-inline-item cursor-pointer" onClick={toggleModal}>
                    <i className="ri-pencil-fill fs-20 text-info"></i>
                </li>
            )}
            <Modal isOpen={modalOpen} toggle={toggleModal} size="lg">
                <ModalHeader toggle={toggleModal}>Modifica Prenotazione</ModalHeader>
                <ModalBody>
                    {error ? (
                        <Alert color="danger">Si è verificato un errore durante il recupero della prenotazione.</Alert>
                    ) : loading ? (
                        <Loading />
                    ) : type ? (
                        showForm()
                    ) : (
                        <div className="container mt-4">
                            <Card className="mb-3">
                                <CardBody>
                                    <CardTitle tag="h5" className="text-primary">Sede</CardTitle>
                                    <h5><strong>{booking.place.name}</strong></h5>
                                    <h5><strong>Indirizzo:</strong> <i>{booking.place.address} - {booking.place.city}</i></h5>
                                    <Button color="primary" disabled size="sm" onClick={() => setType("place")}>Modifica</Button>
                                </CardBody>
                            </Card>
                            <Card className="mb-3">
                                <CardBody>
                                    <CardTitle tag="h5" className="text-primary">Prenotazione</CardTitle>
                                    <Row>
                                        <Col md="6">
                                            <h5><strong>Data:</strong> {moment(booking.date_hour).format("ddd DD MMM YYYY")}</h5>
                                            <h5><strong>Ora:</strong> {moment(booking.date_hour).format("HH:mm")}</h5>
                                            <h5><strong>Servizio:</strong> {booking.service.name}</h5>
                                        </Col>
                                    </Row>
                                    <Button color="primary" size="sm" onClick={() => setType("booking")}>Modifica</Button>
                                </CardBody>
                            </Card>
                            <Card className="mb-3">
                                <CardBody>
                                    <CardTitle tag="h5" className="text-primary">Cliente</CardTitle>
                                    <h5><strong>Nome:</strong> {booking.client.name}</h5>
                                    <h5><strong>Cellulare:</strong> {booking.client.cell}</h5>
                                    <h5><strong>Email:</strong> {booking.client.email}</h5>
                                    <br />
                                    {booking.client.custom_fields.map((c, i) => (
                                        <span key={i}>
                                            <h6>{c.name.toUpperCase()}: <b>{c.value}</b></h6>
                                        </span>
                                    ))}
                                    <Button color="primary" size="sm" onClick={() => setType("client")}>Modifica</Button>
                                </CardBody>
                            </Card>
                        </div>
                    )}
                </ModalBody>
                <ModalFooter>
                    {type === null && (
                        <Button color="success" onClick={saveBooking} disabled={!editCheck}>
                            Modifica Prenotazione
                        </Button>
                    )}
                </ModalFooter>
            </Modal>
        </Fragment>
    );
};

export default EditBooking;
