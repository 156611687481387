import React, { useState, useEffect } from 'react';
import {Alert, Badge} from 'reactstrap';
import { Grid, _ } from 'gridjs-react';
import { ClientAPI } from 'api';
import { Loading } from 'custom';
import {useHistory} from "react-router-dom";

const ClientTable = () => {
    const [clients, setClients] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);

    const history = useHistory()

    useEffect(() => {
        getClients();
    }, []);

    const getClients = async () => {
        try {
            const clientsList = await ClientAPI.list();
            setClients(clientsList);
            setLoading(false);
        } catch (err) {
            console.error(err);
            setError(true);
            setLoading(false);
        }
    };

    if (error) {
        return (
            <React.Fragment>
                <Alert color="danger">
                    Si è verificato un errore durante il recupero della lista clienti.
                </Alert>
            </React.Fragment>
        );
    }

    return (
        <React.Fragment>
            {loading ? (
                <Loading />
            ) : (
                <Grid
                    data={clients}
                    columns={[
                        {
                            id: 'id',
                            name: '#',
                            formatter: (cell, row) => _(
                                <span className="text-nowrap">{cell}</span>
                            ),
                            width: "50px"
                        },
                        {
                            id: 'name',
                            name: 'Nome',
                            formatter: (cell, row) => _(
                                <span className="text-nowrap">
                                    <a href="javascript:void(0)" onClick={() => history.push("/client/" + row.cells[0].data)}>
                                        <h5 className="text-primary cursor-pointer"><b>{cell}</b></h5>
                                    </a>
                                </span>
                            )
                        },
                        {
                            id: 'cell',
                            name: 'Cellulare',
                            formatter: (cell, row) => _(
                                <div className="text-nowrap">
                                    {(cell !== "") ? <>
                                        <a href={"https://api.whatsapp.com/send?phone=" + cell.slice(1)}>
                                            <Badge style={{ fontSize: "12px" }} color="success">
                                                <i className="mdi mdi-whatsapp"></i> Whatsapp</Badge></a>&nbsp;&nbsp;
                                        <a href={"tel:" + cell}>
                                            <Badge style={{ fontSize: "12px" }} color="primary">
                                                <i className="mdi mdi-phone"></i> {cell.slice(0, 3) + " " + cell.slice(3)}
                                            </Badge>
                                        </a>
                                    </> : null}
                                </div>
                            )
                        },
                        {
                            id: 'email',
                            name: 'Email',
                            formatter: (cell, row) => _(
                                <div className="text-nowrap">
                                    {(cell !== "") ? <a href={"mail:" + cell}>
                                        <Badge style={{ fontSize: "12px", marginTop: 8 }} color="secondary">
                                            <i className="mdi mdi-email"></i>&nbsp;&nbsp;{cell}</Badge>
                                    </a> : null}
                                </div>
                            )
                        }
                    ]}
                    style={{ table: { 'white-space': 'nowrap' } }}
                    resizable={true}
                    sort={true}
                    search={true}
                    pagination={{ enabled: true, limit: 10 }}
                    language={{
                        search: {
                            placeholder: '🔍 Cerca...'
                        },
                        pagination: {
                            previous: 'Indietro',
                            next: 'Avanti',
                            showing: 'Stai visualizzando da',
                            results: 'Clienti',
                            to: 'a',
                            of: 'di'
                        },
                        noRecordsFound: 'Nessun cliente trovato',
                        error: 'Si è verificato un errore non previsto'
                    }}
                />
            )}
        </React.Fragment>
    );
};

export default ClientTable;
