import React, { useState, useEffect } from 'react';
import {
    Card,
    CardBody,
    CardHeader,
    Col,
    Container,
    Row,
    Button,
    Input,
    Label,
    FormGroup,
    Badge,
    Spinner,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Alert
} from 'reactstrap';
import { AvailableAPI, OperatorAPI } from 'api';
import Select from 'react-select';

const AvailableSection = () => {
    const [loading, setLoading] = useState(true);
    const [operators, setOperators] = useState([]);
    const [availables, setAvailables] = useState({});
    const [newSlot, setNewSlot] = useState({});
    const [selectedOperator, setSelectedOperator] = useState(null);
    const [error, setError] = useState(null);
    const [errorAdd, setErrorAdd] = useState(null);
    const [modal, setModal] = useState(false);
    const [deleteSlotId, setDeleteSlotId] = useState(null);
    const [createModal, setCreateModal] = useState(false);

    useEffect(() => {
        loadOperators();
    }, []);

    const loadOperators = async () => {
        setLoading(true);
        try {
            const operatorsData = await OperatorAPI.list();
            for (let i = 0; i < operatorsData.length; i++) {
                operatorsData[i].availables = await AvailableAPI.list(operatorsData[i].id);
            }
            setOperators(operatorsData);
            setLoading(false);
        } catch (error) {
            console.error("Error loading operators:", error);
            setLoading(false);
        }
    };

    const toggleModal = (slotId = null) => {
        setDeleteSlotId(slotId);
        setModal(!modal);
    };

    const toggleCreateModal = () => {
        setCreateModal(!createModal);
        setErrorAdd(null);
    };

    const handleAddSlot = () => {
        if (newSlot.start && newSlot.end && newSlot.days && newSlot.days.length > 0) {

            AvailableAPI.add(selectedOperator, newSlot)
                .then(() => {
                    loadOperators();
                    setNewSlot({});
                    setErrorAdd(null);
                    toggleCreateModal();
                })
                .catch(error => {
                    console.error("Error adding slot:", error);
                    setErrorAdd("Errore nell'aggiungere la fascia oraria.");
                });
        } else {
            setErrorAdd("Compila tutti i campi per aggiungere una fascia oraria.");
        }
    };

    const handleEditSlot = (slotId, operatorId, updatedSlot) => {

        AvailableAPI.edit(slotId, updatedSlot)
            .then(() => {
                loadOperators();
                setError(null);
            })
            .catch(error => {
                console.error("Error editing slot:", error);
                if (error.status === "CONFLICTING_SLOT")
                    setError("La modifica va in conflitto con un altro slot");
                else
                    setError("Errore nella modifica della fascia oraria.");
            });
    };

    const handleDeleteSlot = () => {
        if (deleteSlotId) {
            AvailableAPI.remove(deleteSlotId)
                .then(() => {
                    loadOperators();
                    toggleModal();
                    setError(null);
                })
                .catch(error => {
                    console.error("Error removing slot:", error);
                    setError("Errore nella rimozione della fascia oraria.");
                });
        }
    };

    const renderOperatorCards = () => {
        return operators.map(operator => (
            <Col md={12} key={operator.id} className="mb-3">
                <Card className="w-100">
                    <CardHeader>
                        <h5>{operator.name}</h5>
                    </CardHeader>
                    <CardBody>
                        {(operator.availables.length === 0) ? <p>Nessuna fascia oraria trovata per l'operatore {operator.name}</p> : null}
                        {operator.availables.map(available => (
                            <div key={available.id} className="mb-2">
                                <FormGroup className="d-flex flex-column flex-md-row justify-content-between">
                                    <div className="flex-grow-1 mr-2 p-1">
                                        <Label for={`start-${available.id}`}>Da</Label>
                                        <Input
                                            type="time"
                                            id={`start-${available.id}`}
                                            value={available.hour_start}
                                            onChange={(e) =>
                                                handleEditSlot(available.id, operator.id, {
                                                    ...available,
                                                    start: e.target.value
                                                })
                                            }
                                        />
                                    </div>
                                    <div className="flex-grow-1 mr-2 p-1">
                                        <Label for={`end-${available.id}`}>A</Label>
                                        <Input
                                            type="time"
                                            id={`end-${available.id}`}
                                            value={available.hour_end}
                                            onChange={(e) =>
                                                handleEditSlot(available.id, operator.id, {
                                                    ...available,
                                                    end: e.target.value
                                                })
                                            }
                                        />
                                    </div>
                                    <div className="flex-grow-2 mr-2 p-1 w-100 w-md-50">
                                        <Label for={`days-${available.id}`}>Giorni</Label>
                                        <Select
                                            closeMenuOnSelect={false}
                                            id={`days-${available.id}`}
                                            isMulti
                                            options={[
                                                { value: 0, label: 'Lunedì' },
                                                { value: 1, label: 'Martedì' },
                                                { value: 2, label: 'Mercoledì' },
                                                { value: 3, label: 'Giovedì' },
                                                { value: 4, label: 'Venerdì' },
                                                { value: 5, label: 'Sabato' },
                                                { value: 6, label: 'Domenica' }
                                            ]}
                                            value={available.days.map(d => ({ value: d, label: getDayLabel(d) }))}
                                            onChange={(selectedOptions) =>
                                                handleEditSlot(available.id, operator.id, {
                                                    ...available,
                                                    days: selectedOptions ? selectedOptions.map(option => option.value) : []
                                                })
                                            }
                                        />
                                    </div>
                                    <div className="d-flex align-items-center" style={{marginTop: 25}}>
                                        <Button color="danger" size="sm" onClick={() => toggleModal(available.id)}>
                                            <i className="ri-delete-bin-line"></i>
                                        </Button>
                                    </div>
                                    <hr />
                                </FormGroup>
                            </div>
                        ))}
                        <Button color="success" size="sm" onClick={() => {
                            setSelectedOperator(operator.id);
                            toggleCreateModal();
                        }}>
                            <i className="ri-add-fill"></i> Aggiungi Fascia Oraria
                        </Button>
                    </CardBody>
                </Card>
            </Col>
        ));
    };

    const getDayLabel = (day) => {
        switch (day) {
            case 0:
                return 'Lunedì';
            case 1:
                return 'Martedì';
            case 2:
                return 'Mercoledì';
            case 3:
                return 'Giovedì';
            case 4:
                return 'Venerdì';
            case 5:
                return 'Sabato';
            case 6:
                return 'Domenica';
            default:
                return '';
        }
    };

    return (
        <Container fluid>
            <Row>
                <Col md={12} className="p-3">
                    <h4>Gestione Disponibilità Operatori</h4>
                    <p>Seleziona i giorni e gli orari in cui accetti le prenotazioni. Questi intervalli si ripetono ogni settimana.</p>
                    {error && <Alert color="danger">{error}</Alert>}
                    {loading ? <Spinner color="primary" /> : <Row>{renderOperatorCards()}</Row>}
                </Col>
            </Row>

            <Modal isOpen={modal} toggle={toggleModal}>
                <ModalHeader toggle={toggleModal}>Conferma Rimozione</ModalHeader>
                <ModalBody>Sei sicuro di voler rimuovere questa fascia oraria?</ModalBody>
                <ModalFooter>
                    <Button color="danger" onClick={handleDeleteSlot}>Conferma</Button>{' '}
                    <Button color="secondary" onClick={toggleModal}>Annulla</Button>
                </ModalFooter>
            </Modal>

            <Modal isOpen={createModal} toggle={toggleCreateModal}>
                <ModalHeader toggle={toggleCreateModal}>Aggiungi Fascia Oraria</ModalHeader>
                <ModalBody>
                    {errorAdd && <Alert color="danger">{errorAdd}</Alert>}
                    <FormGroup>
                        <Label for="new-slot-start">Da</Label>
                        <Input
                            type="time"
                            id="new-slot-start"
                            value={newSlot.start || ''}
                            onChange={(e) => setNewSlot({ ...newSlot, start: e.target.value })}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label for="new-slot-end">A</Label>
                        <Input
                            type="time"
                            id="new-slot-end"
                            value={newSlot.end || ''}
                            onChange={(e) => setNewSlot({ ...newSlot, end: e.target.value })}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label for="new-slot-days">Giorni</Label>
                        <Select
                            closeMenuOnSelect={false}
                            id="new-slot-days"
                            isMulti
                            options={[
                                { value: 0, label: 'Lunedì' },
                                { value: 1, label: 'Martedì' },
                                { value: 2, label: 'Mercoledì' },
                                { value: 3, label: 'Giovedì' },
                                { value: 4, label: 'Venerdì' },
                                { value: 5, label: 'Sabato' },
                                { value: 6, label: 'Domenica' }
                            ]}
                            value={newSlot.days ? newSlot.days.map(d => ({ value: d, label: getDayLabel(d) })) : []}
                            onChange={(selectedOptions) =>
                                setNewSlot({
                                    ...newSlot,
                                    days: selectedOptions ? selectedOptions.map(option => option.value) : []
                                })}
                        />
                    </FormGroup>
                </ModalBody>
                <ModalFooter>
                    <Button color="success" onClick={handleAddSlot}>Conferma</Button>{' '}
                    <Button color="danger" onClick={toggleCreateModal}>Annulla</Button>
                </ModalFooter>
            </Modal>
        </Container>
    );
};

export default AvailableSection;
