import React, {useEffect} from 'react';
import {Badge, Card, CardHeader, CardBody, Col, Row} from 'reactstrap';
import {_, Grid} from "gridjs-react";
import moment from "moment/moment";
import {DeleteBooking, EditBooking, Params} from "custom";
import {useHistory} from "react-router-dom";

const TabBooking = ({client, onReloadClient}) => {

    const history = useHistory()


    useEffect(() => {

    }, []);

    return (
        <React.Fragment>
            <Row>
                <Col xxl={12}>
                    <Card>
                        <CardHeader>
                            <h5 className="card-title mb-5 d-md-none">Prenotazioni</h5>
                        </CardHeader>
                        <CardBody>
                            <Grid
                                data={client.bookings}
                                columns={[
                                    {
                                        id: 'id',
                                        hidden: true
                                    },
                                    {
                                        id: 'operator',
                                        hidden: true
                                    },
                                    {
                                        id: 'origin',
                                        hidden: true
                                    },
                                    {
                                        id: 'date_start',
                                        name: 'Ora',
                                        formatter: (cell, row) => _(<span className="text-nowrap">
                                            <a href="javascript:void(0)" onClick={() => history.push("/booking/" + row.cells[0].data)}>
                                                <h5 className="cursor-pointer"><i className="mdi mdi-calendar-clock"/>&nbsp;&nbsp;{moment(cell).format("HH:mm")}</h5>
                                                <h6 className="cursor-pointer">{moment(cell).format("ddd DD MMM YYYY")}</h6>
                                            </a>
                                            <p className="text-warning" hidden={row.cells[2].data !== "ONLINE"}><i
                                                className="mdi mdi-web"/>&nbsp;Prenotazione Online</p>
                                        </span>)
                                    },
                                    {
                                        id: 'service',
                                        name: 'Servizio',
                                        formatter: (cell, row) => _(<div>
                                            <span className="service-legend-item" style={{
                                                backgroundColor: cell.color_scheme,
                                                color: 'white',
                                                padding: '3px 6px',
                                                borderRadius: '4px'
                                            }}>
                                                {cell.name}
                                            </span><br/>
                                            <h5 className="mt-2"><i
                                                className="mdi mdi-account"/> {row.cells[1].data.name}</h5>
                                        </div>)
                                    },
                                    {
                                        id: 'custom_fields',
                                        name: 'Dettagli',
                                        formatter: (cell, row) => _(<div>
                                            {cell.map((c, i) => {
                                                return <span key={i}>
                                                    <small>
                                                        {c.name.toUpperCase()}: <b>{c.value}</b>
                                                    </small><br/>
                                                </span>
                                            })}
                                        </div>)
                                    },
                                    {
                                        id: 'id',
                                        name: '',
                                        sort: false,
                                        formatter: (cell, row, i) => _(<ul
                                            className="list-inline hstack gap-2 mb-0">
                                            <EditBooking idBooking={cell} onResolve={onReloadClient}/>
                                            <DeleteBooking id={cell} onResolve={onReloadClient}/>
                                        </ul>)
                                    }
                                ]}
                                style={{table: {'white-space': 'nowrap'}}}
                                resizable={true}
                                search={true}
                                sort={true}
                                pagination={{enabled: true, limit: 25}}
                                language={{
                                    'search': {
                                        'placeholder': '🔍 Cerca...'
                                    },
                                    'pagination': {
                                        'previous': 'Indietro',
                                        'next': 'Avanti',
                                        'showing': 'Stai visualizzando da',
                                        'results': 'prenotazioni',
                                        'to': 'a',
                                        'of': 'di',
                                    },
                                    'noRecordsFound': 'Nessuna prenotazione trovata',
                                    'error': 'Si è verificato un errore non previsto',
                                }}
                            />
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </React.Fragment>
    );
};

export default TabBooking;
