import React, { Component } from 'react';
import {Alert, Badge, Col, Container, Row} from 'reactstrap';
import BreadCrumb from '../../Components/Common/BreadCrumb';
import {withRouter, Link} from "react-router-dom";
import { Grid, _ } from 'gridjs-react';

import {GestOperator, DeleteOperator, Loading, GestPlace, DeletePlace} from "custom";

import {OperatorAPI, ServiceAPI, PlaceAPI} from "api";


class PlaceTable extends Component {

    constructor(props) {
        super(props);
        this.state = {
            places: [],
            loading: true,
            error: false
        };
    }

    componentDidMount() {
        this.getPlaces();
    }

    getPlaces() {
        PlaceAPI.list().then(async (places) => {
            this.setState({loading: false, error: false, places: places});
        }).catch(() => {
            this.setState({error: true});
        })
    }


    render() {

        if (this.state.error) {
            return <React.Fragment>
                <Alert color="danger" >Si è verificato un errore durante il recupero della lista delle sedi.</Alert>
            </React.Fragment>
        }

        return (
            <React.Fragment>
                <div className="flex-shrink-0" style={{margin: 10}} hidden={this.state.places.length > 0}>
                    <GestPlace onResolve={this.getPlaces.bind(this)} />
                </div>
                {(this.state.loading) ? <Loading /> :
                <Grid
                    data={this.state.places}
                    columns={[
                        {
                            id: 'id',
                            name: '#',
                            formatter: (cell, row) => _(
                                <span className="text-nowrap">{cell}</span>
                            ),
                            width: "50px"
                        },
                        {
                            id: 'name',
                            name: 'Nome',
                            formatter: (cell, row) => _(<span className="text-nowrap">
                                <b>{cell}</b><br />
                                <p className="text-warning" hidden={(row.cells[4].data === false)}><i className="mdi mdi-web" />&nbsp;Online</p>
                            </span>)
                        },
                        {
                            id: 'city',
                            name: 'Città',
                            formatter: (cell, row) => _(<span className="text-nowrap"><b>{cell}</b></span>)
                        },
                        {
                            id: 'address',
                            name: 'Indirizzo',
                            formatter: (cell, row) => _(<span className="text-nowrap"><i>{cell}</i></span>)
                        },
                        {
                            id: 'is_online',
                            hidden: true,
                        },
                        {
                            id: 'id',
                            name: '',
                            sort: false,
                            formatter: (cell, row, i) => _(<ul
                                className="list-inline hstack gap-2 mb-0">
                                <GestPlace preset={this.state.places.find((c) => c.id === row.cells[0].data)} onResolve={this.getPlaces.bind(this)} />
                                {/*<DeletePlace place={{id: row.cells[0].data}} onResolve={this.getPlaces.bind(this)} />*/}
                            </ul>)
                        }
                    ]}
                    style={{table: {'white-space': 'nowrap'}}}
                    resizable={true}
                    search={true}
                    sort={true}
                    pagination={{ enabled: true, limit: 10}}
                    language={{
                        'search': {
                            'placeholder': '🔍 Cerca...'
                        },
                        'pagination': {
                            'previous': 'Indietro',
                            'next': 'Avanti',
                            'showing': 'Stai visualizzando da',
                            'results': 'sedi',
                            'to': 'a',
                            'of': 'di',
                        },
                        'noRecordsFound': 'Nessuna sede trovata',
                        'error': 'Si è verificato un errore non previsto',
                    }}
                />}
            </React.Fragment>
        );
    }
}

export default withRouter(PlaceTable);
