import React, {Fragment} from 'react';
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Alert,
    Row,
    Col,
    Label,
    FormFeedback,
    Input,
    FormText
} from 'reactstrap';

import { Utils } from "custom";
import { PlaceAPI } from "api";
import Select from "react-select";

class GestPlace extends React.Component {
    constructor(props) {
        super(props);
        let preset = this.props.preset;

        this.state = {
            form: {
                id: (preset) ? preset.id : null,
                name: (preset) ? preset.name : "",
                address: (preset) ? preset.address : "",
                city: (preset) ? preset.city : "",
                lat: (preset) ? preset.lat : "",
                lng: (preset) ? preset.lng : "",
                is_online: (preset) ? preset.is_online : false,
            },
            loading: false,
            errors: false
        };

    }

    changeForm(key, text) {
        const form = this.state.form;

        if (key === 'lat' || key === 'lng') {
            if (isNaN(Number(text)))
                return;
        }

        form[key] = text;
        this.setState({form: form}, () => this.showModal());
    }

    showModal() {
        let form = this.state.form;
        let errors = this.state.errors;

        global.onSetModal({
            title: (this.props.preset) ? "MODIFICA SEDE" : "CREA SEDE",
            content: <div>
                <Row>
                    <Col md="12">
                        <div className='mb-2'>
                            <Label className='form-label'>
                                Nome
                            </Label>
                            <Input type="text" value={form.name}
                                   invalid={Boolean(errors.name)}
                                   onChange={(e) => this.changeForm('name', e.target.value)}/>
                            <FormFeedback>{errors.name}</FormFeedback>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col md="12">
                        <div className="form-check form-switch form-switch-custom form-switch-primary mb-3">
                            <Input className="form-check-input" type="checkbox" role="switch"  checked={form.is_online}
                                   onChange={(e) => this.changeForm('is_online', e.target.checked)}/>
                            <Label className="form-check-label">Online</Label>
                        </div>
                    </Col>
                </Row>
                <Row hidden={form.is_online}>
                    <Col md="12">
                        <div className='mb-2'>
                            <Label className='form-label'>
                                Città
                            </Label>
                            <Input type="text" value={form.city}
                                   invalid={Boolean(errors.city)}
                                   onChange={(e) => this.changeForm('city', e.target.value)}/>
                            <FormFeedback>{errors.city}</FormFeedback>
                        </div>
                    </Col>
                    <Col md="12">
                        <div className='mb-2'>
                            <Label className='form-label'>
                                Indirizzo
                            </Label>
                            <Input type="text" value={form.address}
                                   invalid={Boolean(errors.address)}
                                   onChange={(e) => this.changeForm('address', e.target.value)}/>
                            <FormFeedback>{errors.address}</FormFeedback>
                        </div>
                    </Col>
                </Row>
                <Row hidden={form.is_online}>
                    <Col md="12">
                        <div className='mb-2'>
                            <Label className='form-label'>
                                Latitudine
                            </Label>
                            <Input type="text" value={form.lat}
                                   invalid={Boolean(errors.lat)}
                                   onChange={(e) => this.changeForm('lat', e.target.value)}/>
                            <FormFeedback>{errors.lat}</FormFeedback>
                        </div>
                    </Col>
                    <Col md="12">
                        <div className='mb-2'>
                            <Label className='form-label'>
                                Longitudine
                            </Label>
                            <Input type="text" value={form.lng}
                                   invalid={Boolean(errors.lng)}
                                   onChange={(e) => this.changeForm('lng', e.target.value)}/>
                            <FormFeedback>{errors.lng}</FormFeedback>
                        </div>
                    </Col>
                </Row>
            </div>,
            isOpen: true,
            toggle: false,
            size: 'lg',
            type: 'info',
            buttons: [
                {label: 'SALVA', color: 'success', onClick: () => this.savePlace()},
                {
                    label: 'CHIUDI',
                    color: 'danger',
                    onClick: () => this.resetForm()
                }
            ]
        })
    }

    resetForm() {
        let preset = this.props.preset;
        this.setState({
            form: {
                id: (preset) ? preset.id : null,
                name: (preset) ? preset.name : "",
                address: (preset) ? preset.address : "",
                city: (preset) ? preset.city : "",
                lat: (preset) ? preset.lat : "",
                lng: (preset) ? preset.lng : "",
                is_online: (preset) ? preset.is_online : false,
            },
            errors: false
        });
        global.onSetModal({isOpen: false, loading: false, content: ""})
    }

    savePlace() {
        const self = this;
        let form = this.state.form;
        let error = {};

        if (form.name === '')
            error.name = "Il campo nome è obbligatorio";

        if (!form.is_online) {
            if (form.city === '')
                error.city = "La città è obbligatoria";

            if (form.address === '')
                error.address = "L'indirizzo è obbligatorio";

            if (form.lat === '')
                error.lat = "La latitudine è obbligatoria";

            if (form.lng === '')
                error.lng = "La longitudine è obbligatoria";
        }

        this.setState({errors: error}, () => this.showModal());

        if (Object.keys(error).length === 0) {
            global.onSetModal({loading: true});

            let add = (form.id === null);
            let api = (add) ? PlaceAPI.add : PlaceAPI.edit;

            let obj = form;

            api(obj).then(() => {
                global.onSetModal({loading: false});
                global.SweetAlert.fire({
                    title: (add) ? 'Sede creata' : 'Sede modificata',
                    text: (add) ? 'La sede è stata creata correttamente.' : 'La sede è stata modificata correttamente.',
                    icon: 'success',
                    allowOutsideClick: false,
                    customClass: {
                        confirmButton: 'btn btn-primary'
                    },
                    confirmButtonText: 'Chiudi',
                    buttonsStyling: false
                }).then(function (result) {
                    if (result.value) {
                        self.resetForm();
                        self.props.onResolve();
                    }
                })
            }).catch((err) => {
                global.onSetModal({loading: false});

                global.SweetAlert.fire({
                    title: 'Errore',
                    text: (add) ? 'Si è verificato un errore durante la creazione della sede' : 'Si è verificato un errore durante la modifica della sede',
                    icon: 'error',
                    customClass: {
                        confirmButton: 'btn btn-primary'
                    }
                });
            })
        }
    }


    render() {
        return (
            <React.Fragment>
                {(this.props.preset) ? <li className="list-inline-item cursor-pointer" onClick={() => this.showModal()}>
                    <i className="ri-pencil-fill fs-20 text-info"></i>
                </li> : <button
                    type="button"
                    className="btn btn-success btn-sm"
                    onClick={() => this.showModal()}>
                    <i className="ri-add-line align-bottom me-1"></i> CREA SEDE
                </button>}
            </React.Fragment>
        )
    }
}

export default GestPlace;
