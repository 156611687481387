import React from 'react';
import { Link } from 'react-router-dom';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';

const Unauthorized = () => {
    document.title ="401 Non Autorizzato | " + process.env.REACT_APP_NAME;
    return (
        <React.Fragment>
            <div className="auth-page-wrapper auth-bg-cover py-5 d-flex justify-content-center align-items-center min-vh-100">
                <div className="bg-overlay"></div>
                <div className="auth-page-content overflow-hidden pt-lg-5">
                    <Container>
                        <Row className="justify-content-center">
                            <Col xl={5}>
                                <Card className="overflow-hidden">
                                    <CardBody className="p-4">
                                        <div className="text-center">
                                            <h1 className="text-primary mb-4">Oops !</h1>
                                            <h4 className="text-uppercase">Spiacente, Non sei autorizzato!</h4>
                                            <p className="text-muted mb-4">Non hai i permessi per visualizzare la pagina!</p>
                                            <Link to="/" className="btn btn-success"><i className="mdi mdi-home me-1"></i>Torna alla Home</Link>&nbsp;&nbsp;
                                            <a href="javascript:void(0)" className="btn btn-danger" onClick={() => global.checkAuth("UNAUTHORIZED")}><i className="mdi mdi-logout me-1"></i>Logout</a>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
        </React.Fragment >
    );
};

export default Unauthorized;
