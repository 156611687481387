let list = function() {
    return new Promise(function(resolve, reject) {
        const hash = localStorage.getItem('hash');

        fetch(global.route.service, {
            headers: {
                'x-access-token': hash
            },
            method: 'GET'
        })
            .then((response) => (response.status !== 500) ? response.json() : reject())
            .then((responseJson) => {
                global.checkAuth(responseJson.status);
                console.log('service list', responseJson);
                if (responseJson.status === "LIST_SERVICES")
                    resolve(responseJson.list);
                else
                    reject(responseJson);
            })
            .catch((e) => {
                console.error(e.message);
                reject('APP_ERROR');
            });
    });
};

let add = function(service) {
    return new Promise(function(resolve, reject) {
        const hash = localStorage.getItem('hash');

        fetch(global.route.service, {
            headers: {
                'x-access-token': hash,
                'Content-Type': 'application/json'
            },
            method: 'PUT',
            body: JSON.stringify({
                name: service.name,
                minutes_duration: service.minutes_duration,
                days_rolling: service.days_rolling,
                description: service.description,
                color_scheme: service.color_scheme,
                custom_fields: service.custom_fields,
                name_code: service.name_code
            })
        })
            .then((response) => (response.status !== 500) ? response.json() : reject())
            .then((responseJson) => {
                global.checkAuth(responseJson.status);
                console.log('service add', responseJson);
                if (responseJson.status === "SERVICE_CREATED")
                    resolve(responseJson);
                else
                    reject(responseJson);
            })
            .catch((e) => {
                console.error(e.message);
                reject('APP_ERROR');
            });
    });
};

let uploadImage = function(id, file) {
    return new Promise(function(resolve, reject) {
        const hash = localStorage.getItem('hash');
        const formdata = new FormData();
        formdata.append('file', file)

        fetch(global.route.serviceImage + "/" + id, {
            headers: {
                'x-access-token': hash
            },
            method: 'PUT',
            body: formdata
        })
            .then((response) => (response.status !== 500) ? response.json() : console.log(response))
            .then((responseJson) => {
                global.checkAuth(responseJson.status);
                console.log('upload image service', responseJson);
                if (responseJson.status === "IMAGE_UPLOAD_OK")
                    resolve(responseJson);
                else
                    reject(responseJson);
            })
            .catch((e) => {
                console.error(e);
                reject('APP_ERROR');
            });
    });
};

let edit = function(service) {
    return new Promise(function(resolve, reject) {
        const hash = localStorage.getItem('hash');

        fetch(global.route.service, {
            headers: {
                'x-access-token': hash,
                'Content-Type': 'application/json'
            },
            method: 'POST',
            body: JSON.stringify({
                id: service.id,
                name: service.name,
                minutes_duration: service.minutes_duration,
                days_rolling: service.days_rolling,
                description: service.description,
                color_scheme: service.color_scheme,
                custom_fields: service.custom_fields,
                name_code: service.name_code
            })
        })
            .then((response) => (response.status !== 500) ? response.json() : reject())
            .then((responseJson) => {
                global.checkAuth(responseJson.status);
                console.log('service edit', responseJson);
                if (responseJson.status === "SERVICE_EDITED")
                    resolve(responseJson);
                else
                    reject(responseJson);
            })
            .catch((e) => {
                console.error(e.message);
                reject('APP_ERROR');
            });
    });
};


let removeImage = function(id) {
    return new Promise(function(resolve, reject) {
        const hash = localStorage.getItem('hash');

        fetch(global.route.serviceImage, {
            headers: {
                'x-access-token': hash,
                'Content-Type': 'application/json'
            },
            method: 'DELETE',
            body: JSON.stringify({
                id: id
            })
        })
            .then((response) => (response.status !== 500) ? response.json() : reject())
            .then((responseJson) => {
                global.checkAuth(responseJson.status);
                console.log('image service delete', responseJson);
                if (responseJson.status === "IMAGE_DELETED_OK")
                    resolve(responseJson);
                else
                    reject(responseJson);
            })
            .catch((e) => {
                console.error(e.message);
                reject('APP_ERROR');
            });
    });
};
let remove = function(id) {
    return new Promise(function(resolve, reject) {
        const hash = localStorage.getItem('hash');

        fetch(global.route.service, {
            headers: {
                'x-access-token': hash,
                'Content-Type': 'application/json'
            },
            method: 'DELETE',
            body: JSON.stringify({
                id: id
            })
        })
            .then((response) => (response.status !== 500) ? response.json() : reject())
            .then((responseJson) => {
                global.checkAuth(responseJson.status);
                console.log('service delete', responseJson);
                if (responseJson.status === "SERVICE_DELETED")
                    resolve(responseJson);
                else
                    reject(responseJson);
            })
            .catch((e) => {
                console.error(e.message);
                reject('APP_ERROR');
            });
    });
};

const ServiceAPI = {
    list: list,
    uploadImage: uploadImage,
    add: add,
    edit: edit,
    removeImage: removeImage,
    remove: remove
};

module.exports = ServiceAPI;
