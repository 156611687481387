import _AuthAPI from './AuthAPI';
import _UserAPI from './UserAPI';
import _CompanyAPI from './CompanyAPI';
import _AdminAPI from './AdminAPI';
import _ClientAPI from './ClientAPI';
import _AvailableAPI from './AvailableAPI';
import _CategoryAPI from './CategoryAPI';
import _ServiceAPI from './ServiceAPI';
import _OperatorAPI from './OperatorAPI';
import _AppointmentAPI from './AppointmentAPI';
import _CloseDayAPI from './CloseDayAPI';
import _BookingAPI from './BookingAPI';
import _PlaceAPI from './PlaceAPI';

export const AuthAPI = _AuthAPI;
export const UserAPI = _UserAPI;
export const CompanyAPI = _CompanyAPI;
export const AdminAPI = _AdminAPI;
export const ClientAPI = _ClientAPI;
export const AvailableAPI = _AvailableAPI;
export const CategoryAPI = _CategoryAPI;
export const ServiceAPI = _ServiceAPI;
export const OperatorAPI = _OperatorAPI;
export const AppointmentAPI = _AppointmentAPI;
export const CloseDayAPI = _CloseDayAPI;
export const BookingAPI = _BookingAPI;
export const PlaceAPI = _PlaceAPI;



