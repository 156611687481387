let list = function() {
    return new Promise(function(resolve, reject) {
        const hash = localStorage.getItem('hash');
        const company = localStorage.getItem('companySelected');

        fetch(global.route.category, {
            headers: {'x-access-token': hash, 'x-access-company': company},
            method: 'GET'
        })
            .then((response) => (response.status !== 500) ? response.json() : reject())
            .then((responseJson) => {
                global.checkAuth(responseJson.status);
                console.log('category list', responseJson);
                if (responseJson.status === "LIST_CATEGORIES")
                    resolve(responseJson.list);
                else
                    reject(responseJson);
            })
            .catch((e) => {
                console.error(e.message);
                reject('APP_ERROR');
            });
    });
};

let add = function(category) {
    return new Promise(function(resolve, reject) {
        const hash = localStorage.getItem('hash');
        const company = localStorage.getItem('companySelected');

        fetch(global.route.category, {
            headers: {
                'x-access-token': hash,
                'x-access-company': company,
                'Content-Type': 'application/json'
            },
            method: 'PUT',
            body: JSON.stringify({
                name: category.name,
                enabled: category.enabled
            })
        })
            .then((response) => (response.status !== 500) ? response.json() : reject())
            .then((responseJson) => {
                global.checkAuth(responseJson.status);
                console.log('category add', responseJson);
                if (responseJson.status === "CATEGORY_CREATED")
                    resolve(responseJson);
                else
                    reject(responseJson);
            })
            .catch((e) => {
                console.error(e.message);
                reject('APP_ERROR');
            });
    });
};

let edit = function(category) {
    return new Promise(function(resolve, reject) {
        const hash = localStorage.getItem('hash');
        const company = localStorage.getItem('companySelected');

        fetch(global.route.category, {
            headers: {
                'x-access-token': hash,
                'x-access-company': company,
                'Content-Type': 'application/json'
            },
            method: 'POST',
            body: JSON.stringify({
                id: category.id,
                name: category.name,
                enabled: category.enabled
            })
        })
            .then((response) => (response.status !== 500) ? response.json() : reject())
            .then((responseJson) => {
                global.checkAuth(responseJson.status);
                console.log('category edit', responseJson);
                if (responseJson.status === "CATEGORY_EDITED")
                    resolve(responseJson);
                else
                    reject(responseJson);
            })
            .catch((e) => {
                console.error(e.message);
                reject('APP_ERROR');
            });
    });
};

let remove = function(id) {
    return new Promise(function(resolve, reject) {
        const hash = localStorage.getItem('hash');
        const company = localStorage.getItem('companySelected');

        fetch(global.route.category, {
            headers: {
                'x-access-token': hash,
                'x-access-company': company,
                'Content-Type': 'application/json'
            },
            method: 'DELETE',
            body: JSON.stringify({
                id: id
            })
        })
            .then((response) => (response.status !== 500) ? response.json() : reject())
            .then((responseJson) => {
                global.checkAuth(responseJson.status);
                console.log('category delete', responseJson);
                if (responseJson.status === "CATEGORY_DELETED")
                    resolve(responseJson);
                else
                    reject(responseJson);
            })
            .catch((e) => {
                console.error(e.message);
                reject('APP_ERROR');
            });
    });
};

const CategoryAPI = {
    list: list,
    add: add,
    edit: edit,
    remove: remove
};

module.exports = CategoryAPI;
