import React, { useState, useEffect } from 'react';
import { Alert, Badge, Button, Col, Container, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import BreadCrumb from '../../Components/Common/BreadCrumb';
import { Link } from "react-router-dom";
import { Grid, _ } from 'gridjs-react';
import { GestSender, DeleteSender, Loading, GestService, DeleteService, Utils } from "custom";
import { ServiceAPI } from "api";
import Dropzone from "react-dropzone";

const ServiceTable = () => {
    const [services, setServices] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [selectedService, setSelectedService] = useState(null);
    const [selectedFile, setSelectedFile] = useState(null);
    const [fileError, setFileError] = useState(null);
    const [imageUrlSelectedFile, setImageUrlSelectedFile] = useState(null);
    const [imageLoading, setImageLoading] = useState(false);

    useEffect(() => {
        getServices();
    }, []);

    const getServices = async () => {
        try {
            const services = await ServiceAPI.list();
            setServices(services);
            setLoading(false);
            setError(false);
        } catch (err) {
            setError(true);
        }
    };

    const copyUrl = (url) => {
        navigator.clipboard.writeText(url);
        global.SweetAlert.fire({
            icon: 'success',
            title: 'Link Copiato',
            timer: 1500,
            showConfirmButton: false
        });
    };

    const onClickImg = (service) => {
        setSelectedService(service);
        setModalOpen(true);
    };

    const onDropFile = (acceptedFiles) => {
        const file = acceptedFiles[0];

        if (!file.type.startsWith('image/')) {
            setFileError('Seleziona un file immagine valido.');
            return;
        }

        if (file.size > 5 * 1024 * 1024) {
            setFileError('Il file supera la dimensione massima di 5MB.');
            return;
        }

        const imageUrl = URL.createObjectURL(file);
        setSelectedFile(file);
        setFileError(null);
        setImageUrlSelectedFile(imageUrl);
    };

    const toggleModal = () => {
        setModalOpen(!modalOpen);
        setSelectedFile(null);
        setFileError(null);
        setImageLoading(false);
        setImageUrlSelectedFile(null);
        getServices()
    };

    const handleSave = () => {
        if (!selectedFile) {
            setFileError('Nessun file selezionato.');
            return;
        }

        setImageLoading(true);

        ServiceAPI.uploadImage(selectedService.id, selectedFile).then((response) => {
            global.SweetAlert.fire({
                icon: 'success',
                title: 'Immagine caricata con successo',
                customClass: {
                    confirmButton: 'btn btn-success'
                }
            });
            toggleModal();
        }).catch(() => {
            global.SweetAlert.fire({
                icon: 'error',
                title: 'Errore durante il caricamento dell\'immagine',
                customClass: {
                    confirmButton: 'btn btn-success'
                }
            });
        });

    };

    const removeImage = () => {
        global.SweetAlert.fire({
            title: 'Elimina immagine',
            text: 'Vuoi veramente eliminare l\'immagine?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Si, elimina',
            cancelButtonText: 'Annulla',
            customClass: {
                confirmButton: 'btn btn-primary',
                cancelButton: 'btn btn-outline-danger ms-1',
            },
            buttonsStyling: false,
            showLoaderOnConfirm: true,
            preConfirm: function () {
                return new Promise(function (resolve, reject) {
                    ServiceAPI.removeImage(selectedService.id).then(() => {
                        resolve();
                    }).catch(() => {
                        global.SweetAlert.fire({
                            icon: 'error',
                            title: 'Si è verificato un errore :(',
                            text: 'Purtroppo qualcosa è andato storto e non è stato possibile eliminare l\'immagine. Riprova più tardi.',
                            customClass: {
                                confirmButton: 'btn btn-success',
                            },
                        });
                        reject();
                    });
                });
            },
        }).then(function (result) {
            if (result.value) {
                toggleModal();
                global.SweetAlert.fire({
                    icon: 'success',
                    title: 'Immagine eliminata!',
                    text: '',
                    customClass: {
                        confirmButton: 'btn btn-success',
                    },
                });
            }
        });
    }

    if (error) {
        return <Alert color="danger">Si è verificato un errore durante il recupero della lista servizi.</Alert>;
    }

    let url_profile = null;

    if (Utils.getUserData().company) {
        url_profile = process.env.REACT_APP_FRONTEND_URL + "/" + Utils.getUserData().company.name_code;
    }

    const noImg = require("../../assets/images/noImage.png");

    return (
        <div>
            {Utils.getUserData().company && (
                <div className="flex-shrink-0" style={{ margin: 10 }}>
                    <Alert color="primary" style={{ cursor: "pointer" }} onClick={() => copyUrl(url_profile)}>
                        Link Profilo: <b>{url_profile}</b>
                    </Alert>
                </div>
            )}
            <div className="flex-shrink-0" style={{ margin: 10 }}>
                <GestService onResolve={getServices} />
            </div>
            {loading ? <Loading /> : (
                <Grid
                    data={services}
                    columns={[
                        { id: 'id', hidden: true },
                        {
                            id: 'image_url',
                            name: '',
                            formatter: (cell, row) => _(
                                <div className="text-center">
                                    <img
                                        src={cell !== null ? cell : noImg}
                                        style={{ maxHeight: 50, cursor: "pointer" }}
                                        onClick={() => onClickImg(services.find(i => i.id === row.cells[0].data))}
                                    />
                                </div>
                            ),
                            width: "100px"
                        },
                        { id: 'color_scheme', hidden: true },
                        {
                            id: 'name',
                            name: 'Nome',
                            formatter: (cell, row) => _(
                                <span className="text-nowrap">
                                    <b style={{ backgroundColor: row.cells[2].data, color: "#fcfcfc", borderRadius: "5px", padding: "5px" }}>
                                        {cell}
                                    </b>
                                </span>
                            )
                        },
                        {
                            id: 'minutes_duration',
                            name: 'Durata (minuti)',
                            formatter: (cell, row) => _(<span className="text-nowrap">{cell}</span>)
                        },
                        {
                            id: 'public_url',
                            name: 'Url Prenotazioni',
                            sort: false,
                            formatter: (cell) => _(
                                <div className="text-nowrap text-center">
                                    <button className="btn btn-sm btn-secondary" onClick={() => copyUrl(cell)}>Copia URL</button>
                                </div>
                            )
                        },
                        {
                            id: 'id',
                            name: '',
                            sort: false,
                            formatter: (cell, row) => _(
                                <ul className="list-inline hstack gap-2 mb-0">
                                    <GestService preset={services.find(c => c.id === row.cells[0].data)} onResolve={getServices} />
                                    <DeleteService category={{ id: row.cells[0].data }} onResolve={getServices} />
                                </ul>
                            )
                        }
                    ]}
                    style={{ table: { 'white-space': 'nowrap' } }}
                    resizable={true}
                    search={true}
                    sort={true}
                    pagination={{ enabled: true, limit: 10 }}
                    language={{
                        'search': { 'placeholder': '🔍 Cerca...' },
                        'pagination': {
                            'previous': 'Indietro',
                            'next': 'Avanti',
                            'showing': 'Stai visualizzando da',
                            'results': 'servizi',
                            'to': 'a',
                            'of': 'di'
                        },
                        'noRecordsFound': 'Nessun servizio trovato',
                        'error': 'Si è verificato un errore non previsto'
                    }}
                />
            )}

            <Modal isOpen={modalOpen} toggle={toggleModal}>
                <ModalHeader toggle={toggleModal}>Carica immagine</ModalHeader>
                <ModalBody>
                    {imageLoading ? <Loading /> : (
                        <div>
                            {selectedService?.image_url ? (
                                <>
                                    <img src={selectedService.image_url} alt="Immagine del servizio" style={{ width: '100%', marginBottom: '10px' }} />
                                    <div className="text-center">
                                        <Button color="danger" size="sm" className="m-1" onClick={removeImage}>Rimuovi Immagine</Button>
                                    </div>
                                    <p>Se vuoi, puoi sostituire l'immagine trascinando un file qui sotto o selezionandolo manualmente.</p>
                                </>
                            ) : (
                                <p>Trascina un'immagine qui sotto o seleziona un file manualmente per caricare l'immagine.</p>
                            )}
                            <Dropzone onDrop={onDropFile} accept="image/*" multiple={false}>
                                {({ getRootProps, getInputProps }) => (
                                    <div {...getRootProps()} className="dropzone" style={{
                                        display: 'flex', flexDirection: 'column', alignItems: 'center',
                                        justifyContent: 'center', border: '2px dashed #cccccc', borderRadius: '5px',
                                        padding: '20px', height: '200px', cursor: 'pointer', backgroundColor: '#f9f9f9'
                                    }}>
                                        <input {...getInputProps()} />
                                        {selectedFile ? (
                                            <img src={imageUrlSelectedFile} alt="Immagine del servizio" style={{ width: '100%', maxWidth: "150px", marginBottom: '10px' }} />
                                        ) : (
                                            <h4 style={{ margin: '10px 0', textAlign: 'center', color: '#666666' }}>
                                                Trascina qui un file o clicca per selezionarlo
                                            </h4>
                                        )}
                                        {!selectedFile && <i className="text-primary mdi mdi-image-area" style={{ fontSize: '50px', marginTop: '10px' }} />}
                                    </div>
                                )}
                            </Dropzone>

                            {fileError && <Alert color="danger">{fileError}</Alert>}
                        </div>
                    )}
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={toggleModal}>Annulla</Button>
                    <Button color="primary" onClick={handleSave} disabled={!selectedFile}>Upload</Button>
                </ModalFooter>
            </Modal>
        </div>
    );
};

export default ServiceTable;
