import _Loading from './Loading';
import _Utils from './Utils';
import _Params from './Params';
import _CustomModal from './CustomModal';

import _GestCompany from "./Components/CompanyAction/GestCompany";
import _DeleteCompany from "./Components/CompanyAction/DeleteCompany";

import _ListAdmin from "./Components/AdminAction/ListAdmin";
import _GestAdmin from "./Components/AdminAction/GestAdmin";
import _DeleteAdmin from "./Components/AdminAction/DeleteAdmin";

import _AddBooking from "./Components/BookingAction/AddBooking";
import _EditBooking from "./Components/BookingAction/EditBooking";
import _DeleteBooking from "./Components/BookingAction/DeleteBooking";

import _GestService from "./Components/ServiceAction/GestService";
import _DeleteService from "./Components/ServiceAction/DeleteService";

import _GestOperator from "./Components/OperatorAction/GestOperator";
import _DeleteOperator from "./Components/OperatorAction/DeleteOperator";

import _GestPlace from "./Components/PlaceAction/GestPlace";
import _DeletePlace from "./Components/PlaceAction/DeletePlace";



export const Loading = _Loading;
export const CustomModal = _CustomModal;
export const Utils = _Utils;
export const Params = _Params;

export const GestCompany = _GestCompany;
export const DeleteCompany = _DeleteCompany;

export const GestPlace = _GestPlace;
export const DeletePlace = _DeletePlace;

export const ListAdmin = _ListAdmin;
export const GestAdmin = _GestAdmin;
export const DeleteAdmin = _DeleteAdmin;

export const AddBooking = _AddBooking;
export const EditBooking = _EditBooking;
export const DeleteBooking = _DeleteBooking;
export const GestService = _GestService;
export const DeleteService = _DeleteService;

export const GestOperator = _GestOperator;
export const DeleteOperator = _DeleteOperator;

