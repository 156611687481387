import React, {Fragment} from 'react';
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Alert,
    Row,
    Col,
    Label,
    FormFeedback,
    Input,
    FormText
} from 'reactstrap';

import { Utils } from "custom";
import { OperatorAPI } from "api";
import Select from "react-select";

class GestOperator extends React.Component {
    constructor(props) {
        super(props);
        let preset = this.props.preset;

        this.state = {
            form: {
                id: (preset) ? preset.id : null,
                name: (preset) ? preset.name : '',
                services: (preset) ? preset.services : [],
                place_id: (preset) ? this.props.places.find((p) => p.value === preset.place.id) : null,
            },
            loading: false,
            errors: false
        };
    }

    changeForm(key, text) {
        const form = this.state.form;

        if (key === 'minutes_duration') {
            if (isNaN(Number(text)))
                return;
        }

        form[key] = text;
        this.setState({form: form}, () => this.showModal());
    }

    showModal() {
        let form = this.state.form;
        let errors = this.state.errors;

        global.onSetModal({
            title: (this.props.preset) ? "MODIFICA OPERATORE" : "CREA OPERATORE",
            content: <div>
                <Row>
                    <Col>
                        <Label className='form-label'>
                            Sede operativa
                        </Label>
                        <Select
                            value={this.state.form.place_id}
                            isClearable
                            isSearchable
                            name="Sede"
                            placeholder="Seleziona la sede..."
                            options={this.props.places}
                            className="basic-multi-select"
                            classNamePrefix="select"
                            closeMenuOnSelect={false}
                            onChange={(c) => {
                                this.changeForm("place_id", c)
                            }}
                        />
                        {(errors.place_id) ? <Alert color="danger">{errors.place_id}</Alert> : null}
                    </Col>
                </Row>
                <br />
                <Row>
                    <Col>
                        <Label className='form-label'>
                            Servizi
                        </Label>
                        <Select
                            value={this.state.form.services}
                            isMulti
                            isClearable
                            isSearchable
                            name="Servizi"
                            placeholder="Seleziona i servizi..."
                            options={this.props.services}
                            className="basic-multi-select"
                            classNamePrefix="select"
                            closeMenuOnSelect={false}
                            onChange={(c) => {
                                this.changeForm("services", c)
                            }}
                        />
                    </Col>
                </Row>
                <br />
                <br />
                <Row>
                    <Col md="12">
                        <div className='mb-2'>
                            <Label className='form-label'>
                                Nome
                            </Label>
                            <Input type="text" value={form.name}
                                   invalid={Boolean(errors.name)}
                                   onChange={(e) => this.changeForm('name', e.target.value)}/>
                            <FormFeedback>{errors.name}</FormFeedback>
                        </div>
                    </Col>
                </Row>
            </div>,
            isOpen: true,
            toggle: false,
            size: 'lg',
            type: 'info',
            buttons: [
                {label: 'SALVA', color: 'success', onClick: () => this.saveOperator()},
                {
                    label: 'CHIUDI',
                    color: 'danger',
                    onClick: () => this.resetForm()
                }
            ]
        })
    }

    resetForm() {
        let preset = this.props.preset;
        this.setState({
            form: {
                id: (preset) ? preset.id : null,
                name: (preset) ? preset.name : '',
                services: (preset) ? preset.services : [],
                place_id: (preset) ? preset.place_id : null,
            },
            errors: false
        });
        global.onSetModal({isOpen: false, loading: false, content: ""})
    }

    saveOperator() {
        const self = this;
        let form = this.state.form;
        let error = {};

        if (form.name === '')
            error.name = "Il campo nome è obbligatorio";

        if (form.place_id === null)
            error.place_id = "La sede è obbligatoria";


        this.setState({errors: error}, () => this.showModal());

        if (Object.keys(error).length === 0) {
            global.onSetModal({loading: true});

            let add = (form.id === null);
            let api = (add) ? OperatorAPI.add : OperatorAPI.edit;

            let obj = form;
            obj.services = form.services.map((s) => s.value)

            obj.place_id = form.place_id.value

            api(obj).then(() => {
                global.onSetModal({loading: false});
                global.SweetAlert.fire({
                    title: (add) ? 'Operatore creato' : 'Operatore modificato',
                    text: (add) ? 'L\'operatore è stato creato correttamente.' : 'L\'operatore è stato modificato correttamente.',
                    icon: 'success',
                    allowOutsideClick: false,
                    customClass: {
                        confirmButton: 'btn btn-primary'
                    },
                    confirmButtonText: 'Chiudi',
                    buttonsStyling: false
                }).then(function (result) {
                    if (result.value) {
                        self.resetForm();
                        self.props.onResolve();
                    }
                })
            }).catch((err) => {
                global.onSetModal({loading: false});

                global.SweetAlert.fire({
                    title: 'Errore',
                    text: (add) ? 'Si è verificato un errore durante la creazione dell\'operatore' : 'Si è verificato un errore durante la modifica dell\'operatore',
                    icon: 'error',
                    customClass: {
                        confirmButton: 'btn btn-primary'
                    }
                });
            })
        }
    }


    render() {
        return (
            <React.Fragment>
                {(this.props.preset) ? <li className="list-inline-item cursor-pointer" onClick={() => this.showModal()}>
                    <i className="ri-pencil-fill fs-20 text-info"></i>
                </li> : <button
                    type="button"
                    className="btn btn-success btn-sm"
                    onClick={() => this.showModal()}>
                    <i className="ri-add-line align-bottom me-1"></i> CREA OPERATORE
                </button>}
            </React.Fragment>
        )
    }
}

export default GestOperator;
