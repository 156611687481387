import React, {Component, useEffect, useState} from 'react';
import {
    Alert,
    Card,
    CardBody,
    CardHeader,
    Col,
    Container,
    Row,
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane,
    Badge,
    Label,
    Input, Spinner
} from 'reactstrap';
import BreadCrumb from '../../Components/Common/BreadCrumb';
import {Loading, Utils} from 'custom';
import {Grid, _} from 'gridjs-react';
import {AvailableAPI} from 'api';
import {Link} from "react-router-dom";
import {Skeleton} from "@mui/material";
import AvailableSection from "./AvailableSection";
import CloseDaySection from "./CloseDaysSection";

const moment = require("moment");


const AvailableMain = () => {

    const [tab, setTab] = useState(1);

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title="Disponibilità" pageTitle={process.env.REACT_APP_NAME}/>
                    <Row>
                        <Col lg={12}>
                            <Card>
                                <CardBody className="checkout-tab">
                                    <div className="step-arrow-nav mt-n3 mx-n3">
                                        <Nav className="nav-pills nav-justified custom-nav" role="tablist">
                                            <NavItem>
                                                <NavLink href="#"
                                                         className={tab === 1 ? "active fs-15 p-3" : "fs-15 p-3"}
                                                         onClick={() => {
                                                             setTab(() => 1)
                                                         }}
                                                >
                                                    <i className="ri-calendar-check-fill fs-16 p-2 bg-soft-primary text-primary rounded-circle align-middle me-2"></i>
                                                    DISPONIBILITÀ
                                                </NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink href="#"
                                                         className={tab === 2 ? "active fs-15 p-3" : "fs-15 p-3"}
                                                         onClick={() => {
                                                             setTab(() => 2)
                                                         }}
                                                >
                                                    <i className="ri-rest-time-fill fs-16 p-2 bg-soft-primary text-primary rounded-circle align-middle me-2"></i>
                                                    GIORNI CHIUSURA
                                                </NavLink>
                                            </NavItem>
                                        </Nav>
                                        <TabContent activeTab={tab}>
                                            <TabPane tabId={1} id="pills-bill-info">
                                                <div className="w-100 p-1">
                                                    <AvailableSection/>
                                                </div>
                                            </TabPane>
                                            <TabPane tabId={2} id="pills-bill-info">
                                                <div className="w-100 p-1">
                                                    <CloseDaySection/>
                                                </div>
                                            </TabPane>
                                        </TabContent>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default AvailableMain
