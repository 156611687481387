import React, { Fragment } from 'react';
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Alert,
    Row,
    Col,
    Label,
    FormFeedback,
    Input,
    FormText
} from 'reactstrap';

import { BlockPicker } from 'react-color';
import Select from "react-select";
import { ServiceAPI } from "api";

const colorsAvailables = require("../../../assets/colors.json");

class GestService extends React.Component {
    constructor(props) {
        super(props);
        let preset = this.props.preset;

        this.state = {
            form: {
                id: (preset) ? preset.id : null,
                name: (preset) ? preset.name : '',
                minutes_duration: (preset) ? preset.minutes_duration : 10,
                days_rolling: (preset) ? preset.days_rolling : '',
                description: (preset) ? preset.description : '',
                color_scheme: (preset) ? preset.color_scheme : '',
                custom_fields: (preset) ? preset.custom_fields : [],
                name_code: (preset) ? preset.name_code : ''
            },
            loading: false,
            errors: false
        };
    }

    changeForm(key, value) {
        const form = this.state.form;

        if (key === 'minutes_duration') {
            if (isNaN(Number(value)))
                return;
        }

        form[key] = value;
        this.setState({ form: form }, () => this.showModal());
    }

    handleColorChange = (color) => {
        this.changeForm('color_scheme', color.hex);
    }

    changeCustomField(index, key, value) {
        const customFields = [...this.state.form.custom_fields];
        customFields[index][key] = value;
        this.setState(prevState => ({
            form: {
                ...prevState.form,
                custom_fields: customFields
            }
        }), () => this.showModal());
    }

    addCustomField() {
        const newField = {
            id: null,
            name: "",
            type: null,
            required: false,
            allowed_multiple_values: []
        };

        this.setState(prevState => ({
            form: {
                ...prevState.form,
                custom_fields: [...prevState.form.custom_fields, newField]
            }
        }), () => this.showModal());
    }

    removeCustomField(index) {
        const customFields = [...this.state.form.custom_fields];
        customFields.splice(index, 1);
        this.setState(prevState => ({
            form: {
                ...prevState.form,
                custom_fields: customFields
            }
        }), () => this.showModal());
    }

    getLabelFromType(type) {
        const types = {
            'TEXT': 'Testo',
            'EMAIL': 'Email',
            'NUMBER': 'Numero',
            'TEXTAREA': 'Area',
            'PHONE': 'Telefono',
            'SELECT': 'Scelta multipla'
        };
        return types[type] || '';
    }

    showModal() {
        let form = this.state.form;
        let errors = this.state.errors;

        global.onSetModal({
            title: (this.props.preset) ? "MODIFICA SERVIZIO" : "CREA SERVIZIO",
            content: <div>
                <Row>
                    <Col md="4">
                        <div className='mb-2'>
                            <Label className='form-label'>
                                Nome
                            </Label>
                            <Input type="text" value={form.name}
                                   invalid={Boolean(errors.name)}
                                   onChange={(e) => this.changeForm('name', e.target.value)} />
                            <FormFeedback>{errors.name}</FormFeedback>
                        </div>
                    </Col>
                    <Col md="4">
                        <div className='mb-2'>
                            <Label className='form-label'>
                                Durata (minuti)
                            </Label>
                            <div className="d-flex align-items-center">
                                <Button
                                    color="primary"
                                    size="sm"
                                    disabled={this.state.form.minutes_duration <= 5}
                                    onClick={() => this.changeForm('minutes_duration', Math.max(5, this.state.form.minutes_duration - 5))}
                                >
                                    -
                                </Button>
                                <Input
                                    type="text"
                                    value={this.state.form.minutes_duration}
                                    readOnly
                                    className="mx-2 text-center"
                                    style={{ width: '60px' }}
                                />
                                <Button
                                    color="primary"
                                    size="sm"
                                    onClick={() => this.changeForm('minutes_duration', Number(this.state.form.minutes_duration) + 5)}
                                >
                                    +
                                </Button>
                            </div>
                            <FormFeedback>{this.state.errors.minutes_duration}</FormFeedback>
                            <small>Durata minima 5 minuti</small>
                        </div>
                    </Col>
                    <Col md="4">
                        <div className='mb-2'>
                            <Label className='form-label'>
                                Disponibilità giorni
                            </Label>
                            <Input type="text" value={form.days_rolling}
                                   invalid={Boolean(errors.days_rolling)}
                                   onChange={(e) => this.changeForm('days_rolling', e.target.value)} />
                            <FormFeedback>{errors.days_rolling}</FormFeedback>
                            <small>Offri la disponibilità degli appuntamenti per un certo numero di giorni nel futuro</small>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className='mb-2'>
                            <Label className='form-label'>
                                Identificativo url
                            </Label>
                            <Input type="text" value={form.name_code}
                                   invalid={Boolean(errors.name_code)}
                                   onChange={(e) => this.changeForm('name_code', e.target.value)} />
                            <FormFeedback>{errors.name_code}</FormFeedback>
                            <small>Scegli un identificativo univoco per ricevere prenotazioni</small>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className='mb-2'>
                            <Label className='form-label'>
                                Campi personalizzati
                            </Label>
                            <br />
                            {form.custom_fields.map((field, index) => (
                                <div key={index} className="mb-4 p-3 border">
                                    <Row>
                                        <Col md="4">
                                            <Label className='form-label'>Tipo</Label>
                                            <Select
                                                placeholder="Seleziona tipologia"
                                                options={[
                                                    { value: 'TEXT', label: 'Testo' },
                                                    { value: 'EMAIL', label: 'Email' },
                                                    { value: 'NUMBER', label: 'Numero' },
                                                    { value: 'TEXTAREA', label: 'Area' },
                                                    { value: 'PHONE', label: 'Telefono' },
                                                    { value: 'SELECT', label: 'Scelta multipla' }
                                                ]}
                                                value={field.type ? { value: field.type, label: this.getLabelFromType(field.type) } : null}
                                                onChange={(e) => this.changeCustomField(index, 'type', e.value)}
                                            />
                                        </Col>
                                        {field.type && (
                                            <>
                                                <Col md="4">
                                                    <Label className='form-label'>Nome</Label>
                                                    <Input
                                                        type="text"
                                                        value={field.name}
                                                        invalid={Boolean(this.state.errors[`custom_fields_name_${index}`])}
                                                        onChange={(e) => this.changeCustomField(index, 'name', e.target.value)}
                                                    />
                                                    <FormFeedback>{this.state.errors[`custom_fields_name_${index}`]}</FormFeedback>
                                                </Col>
                                                <Col md="2">
                                                    <Input
                                                        className="cursor-pointer"
                                                        type="checkbox"
                                                        checked={field.required}
                                                        onChange={(e) => this.changeCustomField(index, 'required', e.target.checked)}
                                                    />
                                                    <Label className='form-label'>Obbligatorio</Label>
                                                </Col>
                                                <Col md="2" className="m-2">
                                                    <Button
                                                        color="danger"
                                                        size="sm"
                                                        onClick={() => this.removeCustomField(index)}
                                                    >
                                                        Rimuovi
                                                    </Button>
                                                </Col>
                                            </>
                                        )}
                                    </Row>

                                    {field.type === 'SELECT' && (
                                        <Row className="mt-3">
                                            <Col>
                                                <Label className='form-label'>Valori multipli</Label>
                                                <Input
                                                    type="textarea"
                                                    rows={3}
                                                    value={field.allowed_multiple_values.join(", ")}
                                                    invalid={Boolean(this.state.errors[`custom_fields_values_${index}`])}
                                                    onChange={(e) =>
                                                        this.changeCustomField(index, 'allowed_multiple_values', e.target.value.split(',').map(item => item.trim()))
                                                    }
                                                    placeholder="Inserisci i valori separati da una virgola"
                                                />
                                                <FormFeedback>{this.state.errors[`custom_fields_values_${index}`]}</FormFeedback>
                                            </Col>
                                        </Row>
                                    )}
                                </div>
                            ))}


                            <Button color="default" size="sm" onClick={() => this.addCustomField()}>
                                + Aggiungi campo personalizzato
                            </Button>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className='mb-2'>
                            <Label className='form-label'>
                                Descrizione
                            </Label>
                            <Input type="textarea" rows={5} value={form.description}
                                   invalid={Boolean(errors.description)}
                                   onChange={(e) => this.changeForm('description', e.target.value)} />
                            <FormFeedback>{errors.description}</FormFeedback>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className='mb-2'>
                            <Label className='form-label'>
                                Colore tabella
                            </Label>
                            <BlockPicker
                                color={form.color_scheme}
                                colors={colorsAvailables}
                                onChangeComplete={this.handleColorChange}
                                width="100%"
                                styles={{
                                    default: {
                                        input: {
                                            display: 'none'
                                        },
                                        head: {
                                            display: 'none'
                                        }
                                    }
                                }}
                            />
                            <p style={{
                                backgroundColor: form.color_scheme,
                                color: "#ffffff",
                                border: "3px solid #ffffff",
                                padding: "5px",
                                marginTop: "10px",
                                borderRadius: "10px",
                                textAlign: "center"
                            }}>
                                <b>Servizio</b>
                            </p>
                            <FormFeedback>{errors.color_scheme}</FormFeedback>
                            <small>Scegli un colore che ti consenta di gestire il servizio sul calendario</small>
                        </div>
                    </Col>
                </Row>
            </div>,
            isOpen: true,
            toggle: false,
            size: 'lg',
            type: 'info',
            buttons: [
                { label: 'SALVA', color: 'success', onClick: () => this.saveService() },
                {
                    label: 'CHIUDI',
                    color: 'danger',
                    onClick: () => this.resetForm()
                }
            ]
        })
    }

    resetForm() {
        let preset = this.props.preset;
        this.setState({
            form: {
                id: (preset) ? preset.id : null,
                name: (preset) ? preset.name : '',
                minutes_duration: (preset) ? preset.minutes_duration : 10,
                days_rolling: (preset) ? preset.days_rolling : '',
                description: (preset) ? preset.description : '',
                color_scheme: (preset) ? preset.color_scheme : '',
                custom_fields: (preset) ? preset.custom_fields : [],
                name_code: (preset) ? preset.name_code : ''
            },
            errors: false
        });
        global.onSetModal({ isOpen: false, loading: false, content: "" })
    }

    saveService() {
        const self = this;
        let form = this.state.form;
        let error = {};

        form.minutes_duration = Number(form.minutes_duration);

        if (form.name === '')
            error.name = "Il campo nome è obbligatorio";

        if (form.color_scheme === '')
            error.color_scheme = "Il campo colore è obbligatorio";

        if (form.minutes_duration === '')
            error.minutes_duration = "Il campo durata è obbligatorio";

        if (form.minutes_duration < 5)
            error.minutes_duration = "Il campo durata deve essere almeno 5 minuti";

        if (form.minutes_duration % 5 !== 0)
            error.minutes_duration = "Il campo durata deve essere multiplo di 5";

        if (form.days_rolling === '')
            error.days_rolling = "Il campo disponibilità giorni è obbligatorio";

        if (form.name_code === '')
            error.name_code = "Il campo identificativo url è obbligatorio";

        form.custom_fields.forEach((field, index) => {
            if (!field.name) {
                error[`custom_fields_name_${index}`] = "Il campo nome è obbligatorio";
            }
            if (field.type === 'SELECT' && (!field.allowed_multiple_values || field.allowed_multiple_values.length === 0)) {
                error[`custom_fields_values_${index}`] = "Devi inserire almeno un valore";
            }
        });


        this.setState({ errors: error }, () => this.showModal());

        if (Object.keys(error).length === 0) {
            global.onSetModal({ loading: true });

            let add = (form.id === null);
            let api = (add) ? ServiceAPI.add : ServiceAPI.edit;

            api(form).then(() => {
                global.onSetModal({ loading: false });
                global.SweetAlert.fire({
                    title: (add) ? 'Servizio creato' : 'Servizio modificato',
                    text: (add) ? 'Il servizio è stato creato correttamente.' : 'Il servizio è stato modificato correttamente.',
                    icon: 'success',
                    allowOutsideClick: false,
                    customClass: {
                        confirmButton: 'btn btn-primary'
                    },
                    confirmButtonText: 'Chiudi',
                    buttonsStyling: false
                }).then(function (result) {
                    if (result.value) {
                        self.resetForm();
                        self.props.onResolve();
                    }
                })
            }).catch((err) => {
                global.onSetModal({ loading: false });

                if (err.status === "INVALID_SERVICE_NAME_CODE") {
                    this.setState({ errors: {name_code: "il codice identificativo è già esistente"} }, () => this.showModal());
                    return;
                }

                global.SweetAlert.fire({
                    title: 'Errore',
                    text: (add) ? 'Si è verificato un errore durante la creazione del servizio' : 'Si è verificato un errore durante la modifica del servizio',
                    icon: 'error',
                    customClass: {
                        confirmButton: 'btn btn-primary'
                    }
                });
            })
        }
    }

    render() {
        return (
            <React.Fragment>
                {(this.props.preset) ? <li className="list-inline-item cursor-pointer" onClick={() => this.showModal()}>
                    <i className="ri-pencil-fill fs-20 text-info"></i>
                </li> : <button
                    type="button"
                    className="btn btn-success btn-sm"
                    onClick={() => this.showModal()}>
                    <i className="ri-add-line align-bottom me-1"></i> CREA SERVIZIO
                </button>}
            </React.Fragment>
        )
    }
}

export default GestService;
