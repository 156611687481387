import React, {Fragment} from 'react';
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Alert,
    Row,
    Col,
    Label,
    FormFeedback,
    Input,
    FormText
} from 'reactstrap';

import { Utils } from "custom";
import { CompanyAPI } from "api";

class GestCompany extends React.Component {
    constructor(props) {
        super(props);
        let preset = this.props.preset;

        this.state = {
            form: {
                id: (preset) ? preset.id : null,
                active: (preset) ? preset.active : false,
                name: (preset) ? preset.name : "",
                firstname: (preset) ? preset.firstname : "",
                lastname: (preset) ? preset.lastname : "",
                email: (preset) ? preset.email : "",
                cell: (preset) ? preset.cell : "",
                name_code: (preset) ? preset.name_code : "",
            },
            loading: false,
            errors: false
        };
    }

    changeForm(key, text) {
        const form = this.state.form;
        form[key] = text;

        this.setState({form: form}, () => this.showModal());
    }

    showModal() {
        let form = this.state.form;
        let errors = this.state.errors;

        global.onSetModal({
            title: (this.props.preset) ? "MODIFICA AZIENDA" : "CREA AZIENDA",
            content: <div>
                <Row hidden={(form.id === null)}>
                    <Col md="12">
                        <div className="form-check form-switch form-switch-custom form-switch-primary mb-3">
                            <Input className="form-check-input" disabled={!this.props.preset?.isRegistered} type="checkbox" role="switch"  checked={form.active}
                                   onChange={(e) => this.changeForm('active', e.target.checked)}/>
                            <Label className="form-check-label">Attiva / Disattiva Azienda</Label>
                            {(!this.props.preset?.isRegistered) ? <Alert color="warning" >L'utente deve ancora completare la registrazione</Alert> : null}
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col md="6">
                        <div className='mb-2'>
                            <Label className='form-label'>
                                Nome Azienda*
                            </Label>
                            <Input type="text" value={form.name}
                                   invalid={Boolean(errors.name)}
                                   onChange={(e) => this.changeForm('name', e.target.value)}/>
                            <FormFeedback>{errors.name}</FormFeedback>
                        </div>
                    </Col>
                    <Col md="6">
                        <div className='mb-2'>
                            <Label className='form-label'>
                                Slug Azienda*
                            </Label>
                            <Input type="text" value={form.name_code}
                                   invalid={Boolean(errors.name_code)}
                                   onChange={(e) => this.changeForm('name_code', e.target.value)}/>
                            <FormFeedback>{errors.name_code}</FormFeedback>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col md="6">
                        <div className='mb-2'>
                            <Label className='form-label'>
                                Nome*
                            </Label>
                            <Input type="text" value={form.firstname}
                                   invalid={Boolean(errors.firstname)}
                                   onChange={(e) => this.changeForm('firstname', e.target.value)}/>
                            <FormFeedback>{errors.firstname}</FormFeedback>
                        </div>
                    </Col>
                    <Col md="6">
                        <div className='mb-2'>
                            <Label className='form-label'>
                                Cognome*
                            </Label>
                            <Input type="text" value={form.lastname}
                                   invalid={Boolean(errors.lastname)}
                                   onChange={(e) => this.changeForm('lastname', e.target.value)}/>
                            <FormFeedback>{errors.lastname}</FormFeedback>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col md="6">
                        <div className='mb-2'>
                            <Label className='form-label'>
                                Email*
                            </Label>
                            <Input type="text" value={form.email}
                                   invalid={Boolean(errors.email)}
                                   onChange={(e) => this.changeForm('email', e.target.value)}/>
                            <FormFeedback>{errors.email}</FormFeedback>
                        </div>
                    </Col>
                    <Col md="6">
                        <div className='mb-2'>
                            <Label className='form-label'>
                                Cellulare*
                            </Label>
                            <Input type="text" value={form.cell}
                                   invalid={Boolean(errors.cell)}
                                   onChange={(e) => this.changeForm('cell', e.target.value)}/>
                            <FormFeedback>{errors.cell}</FormFeedback>
                        </div>
                    </Col>
                </Row>
            </div>,
            isOpen: true,
            toggle: false,
            size: 'lg',
            type: 'info',
            buttons: [
                {label: 'SALVA', color: 'success', onClick: () => this.saveCompany()},
                {
                    label: 'CHIUDI',
                    color: 'danger',
                    onClick: () => this.resetForm()
                }
            ]
        })
    }

    resetForm() {
        let preset = this.props.preset;
        this.setState({
            form: {
                id: (preset) ? preset.id : null,
                active: (preset) ? preset.active : false,
                name: (preset) ? preset.name : "",
                firstname: (preset) ? preset.firstname : "",
                lastname: (preset) ? preset.lastname : "",
                email: (preset) ? preset.email : "",
                cell: (preset) ? preset.cell : "",
                name_code: (preset) ? preset.name_code : "",
            }
        });
        global.onSetModal({isOpen: false, loading: false, content: ""})
    }

    saveCompany() {
        const self = this;
        let form = this.state.form;
        let error = {};

        if (form.name_code === '')
            error.name_code = "Il campo slug azienda è obbligatorio";

        if (form.name === '')
            error.name = "Il campo nome azienda è obbligatorio";

        if (form.firstname === '')
            error.firstname = "Il campo nome è obbligatorio";

        if (form.lastname === '')
            error.lastname = "Il campo cognome è obbligatorio";

        if (form.email === '')
            error.email = "Il campo email è obbligatorio";

        if (form.cell === '')
            error.cell = "Il campo cellulare è obbligatorio";

        if (form.email !== '' && form.email !== null && !Utils.isValidEmail(form.email))
            error.email = "Il campo email non è corretto";

        if (form.cell !== '' && form.cell !== null && !Utils.isValidCell(form.cell))
            error.cell = "Il campo cellulare non è corretto";

        this.setState({errors: error}, () => this.showModal());

        if (Object.keys(error).length === 0) {
            global.onSetModal({loading: true});

            let add = (form.id === null);
            let api = (add) ? CompanyAPI.add : CompanyAPI.edit;

            let obj = {
                user: {
                    firstname: form.firstname,
                    lastname: form.lastname,
                    email: form.email,
                    cell: form.cell,
                },
                company: {
                    name: form.name,
                    time_zone: "Europe/Rome",
                    name_code: form.name_code
                }
            }

            if (!add) {
                obj.id = form.id;
                obj.active = form.active;
            }


            api(obj).then(() => {
                global.onSetModal({loading: false});
                global.SweetAlert.fire({
                    title: (add) ? 'Azienda creata' : 'Azienda modificata',
                    text: (add) ? 'L\'azienda è stata creata correttamente.' : 'L\'azienda è stata modificata correttamente.',
                    icon: 'success',
                    allowOutsideClick: false,
                    customClass: {
                        confirmButton: 'btn btn-primary'
                    },
                    confirmButtonText: 'Chiudi',
                    buttonsStyling: false
                }).then(function (result) {
                    if (result.value) {
                        self.resetForm();
                        self.props.onResolve();
                    }
                })
            }).catch((err) => {
                console.error(err)
                global.onSetModal({loading: false});
                if(err.status === "COMPANY_EXIST") {
                    error.email = "L'azienda risulta già registrata";
                    this.setState({errors: error}, () => this.showModal())
                    return;
                }
                global.SweetAlert.fire({
                    title: 'Errore',
                    text: (add) ? 'Si è verificato un errore durante la creazione dell\'azienda' : 'Si è verificato un errore durante la modifica dell\'azienda',
                    icon: 'error',
                    customClass: {
                        confirmButton: 'btn btn-primary'
                    }
                });
            })
        }
    }


    render() {
        return (
            <React.Fragment>
                {(this.props.preset) ? <li className="list-inline-item cursor-pointer" onClick={() => this.showModal()}>
                    <i className="ri-pencil-fill fs-20 text-info"></i>
                </li> : <button
                    type="button"
                    className="btn btn-success btn-sm"
                    onClick={() => this.showModal()}>
                    <i className="ri-add-line align-bottom me-1"></i> CREA AZIENDA
                </button>}
            </React.Fragment>
        )
    }
}

export default GestCompany;
