import React, { useEffect, useState } from 'react';
import moment from 'moment';
import '../../../assets/scss/BookingTabMonth.css';

const BookingTabMonth = ({ bookings, setSelectedDay, selectedDay, services, setDaysRange }) => {
    const [daysInMonth, setDaysInMonth] = useState([]);
    const [currentMonth, setCurrentMonth] = useState(moment());

    useEffect(() => {
        setSelectedDay(moment().format('YYYY-MM-DD'))
    }, [])

    useEffect(() => {
        updateDaysInMonth(currentMonth);
    }, [currentMonth]);

    const updateDaysInMonth = (month) => {
        const startOfMonth = month.clone().startOf('month');
        const endOfMonth = month.clone().endOf('month');
        setDaysRange({
            startDay: startOfMonth.toISOString(),
            endDay: endOfMonth.toISOString()
        })
        const days = [];
        let m = startOfMonth;
        while (m.isBefore(endOfMonth) || m.isSame(endOfMonth, 'day')) {
            days.push(m.clone());
            m.add(1, 'day');
        }
        setDaysInMonth(days);
    };

    const handleSelectDay = (day) => {
        setSelectedDay(day.format('YYYY-MM-DD'));
    };

    const handlePrevMonth = () => {
        setCurrentMonth(currentMonth.clone().subtract(1, 'month'));
        setSelectedDay(null);
    };

    const handleNextMonth = () => {
        setCurrentMonth(currentMonth.clone().add(1, 'month'));
        setSelectedDay(null);
    };

    const servicesInBookings = services.filter(service =>
        bookings.some(booking => booking.service.id === service.id)
    );

    return (
        <div className="booking-tab-month">
            <div className="month-navigation">
                <button onClick={handlePrevMonth} className="nav-button">&lt;</button>
                <span className="month-label">{currentMonth.format('MMMM YYYY').toUpperCase()}</span>
                <button onClick={handleNextMonth} className="nav-button">&gt;</button>
            </div>
            <div className="days-grid">
                {daysInMonth.map(day => {
                    const isPastDay = day.isBefore(moment(), 'day');
                    const isSelected = selectedDay === day.format('YYYY-MM-DD');
                    const isCurrentDay = moment().format("YYYY-MM-DD") === day.format('YYYY-MM-DD');
                    const bookingForDay = bookings.find(booking => moment(booking.date_start).isSame(day, 'day'));
                    const serviceColor = bookingForDay ? services.find(service => service.id === bookingForDay.service.id)?.color_scheme : null;

                    return (
                        <div
                            key={day.format('YYYY-MM-DD')}
                            className={`day-box ${isSelected ? 'selected' : ''} ${isPastDay ? 'past-day' : ''} ${isCurrentDay ? 'current-day' : ''}`}
                            onClick={() => handleSelectDay(day)}
                        >
                            <div className="day-number">{day.format('DD')}</div>
                            <div className="day-name">{day.format('ddd')}</div>
                            {serviceColor && <div className="service-indicator" style={{ backgroundColor: serviceColor }}></div>}
                        </div>
                    );
                })}
            </div>
            <div className="services-legend">
                {servicesInBookings.map(service => (
                    <span key={service.id} className="service-legend-item" style={{ backgroundColor: service.color_scheme }}>
                        {service.name}
                    </span>
                ))}
            </div>
        </div>
    );
};

export default BookingTabMonth;
