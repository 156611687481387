import React, { useState, useEffect } from 'react';
import {
    Alert,
    Card,
    CardBody,
    Col,
    Container,
    Row,
    Button,
    Table, Badge
} from 'reactstrap';
import {DeleteBooking, EditBooking, Loading} from 'custom';
import BreadCrumb from '../../Components/Common/BreadCrumb';
import { BookingAPI } from 'api';
import {useHistory, useParams} from 'react-router-dom';
import moment from 'moment';
import 'moment/locale/it'

const BookingDetail = () => {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [bookingDetail, setBookingDetail] = useState(null);

    const { id_booking } = useParams();

    const history = useHistory()

    useEffect(() => {
        getBookingDetail();
    }, []);

    const getBookingDetail = () => {
        BookingAPI.detail(id_booking)
            .then((booking) => {
                setBookingDetail(booking);
                setLoading(false);
            })
            .catch(() => {
                setLoading(false);
                setError(true);
            });
    };

    const handleEditBooking = () => {
        console.log('Modifica prenotazione');
    };

    const handleDeleteBooking = () => {
        console.log('Elimina prenotazione');
    };

    const getBookingStatus = (status) => {
        switch (status) {
            case "BOOKED":
                return <h5 className="text-primary"><i className="mdi mdi-calendar-clock" />&nbsp;Prenotato</h5>
            case "COMPLETED":
                return <h5 className="text-success"><i className="mdi mdi-calendar-check" />&nbsp;Completato</h5>
            case "CANCELED":
                return <h5 className="text-danger"><i className="mdi mdi-calendar-remove" />&nbsp;Annullato</h5>
            default:
                return ""
        }
    };

    const getBookingOrigin = (status) => {
        switch (status) {
            case "ONLINE":
                return <h5 className="text-warning"><i className="mdi mdi-web" />&nbsp;ONLINE</h5>
            case "OFFLINE":
                return <h5>OFFLINE</h5>
            default:
                return ""
        }
    };

    if (error) {
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        <BreadCrumb title="Dettaglio Prenotazione" pageTitle={process.env.REACT_APP_NAME} />
                        <Alert color="danger">
                            Si è verificato un errore durante il recupero del dettaglio prenotazione.
                        </Alert>
                    </Container>
                </div>
            </React.Fragment>
        );
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title="Dettaglio Prenotazione" pageTitle={process.env.REACT_APP_NAME} />
                    {loading ? (
                        <Loading />
                    ) : (
                        <React.Fragment>
                            <Button className="btn btn-sm btn-primary m-1" onClick={() => history.go(-1)}>
                                <i className="ri-arrow-left-circle-line fs-5 text-white-75 align-middle" /> Torna indietro
                            </Button>
                            <Row>
                                <Col lg={12}>
                                    <Card>
                                        <CardBody>
                                            <h5 className="card-title">Informazioni Prenotazione</h5>
                                            <Table responsive striped>
                                                <tbody>
                                                <tr>
                                                    <th>Creata il</th>
                                                    <td>{moment(bookingDetail.createdAt).format('DD/MM/YYYY HH:mm')}</td>
                                                </tr>
                                                <tr>
                                                    <th>Stato</th>
                                                    <td>{getBookingStatus(bookingDetail.status)}</td>
                                                </tr>
                                                <tr>
                                                    <th>Origine</th>
                                                    <td>{getBookingOrigin(bookingDetail.origin)}</td>
                                                </tr>
                                                <tr>
                                                    <th>Servizio</th>
                                                    <td>
                                                        <span className="service-legend-item" style={{ backgroundColor: bookingDetail.service.color_scheme, color: 'white', padding: '3px 6px', borderRadius: '4px' }}>
                                                            {bookingDetail.service.name}
                                                        </span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>Data</th>
                                                    <td><h5>{moment(bookingDetail.date_hour).format('ddd DD MMM YYYY')}</h5></td>
                                                </tr>
                                                <tr>
                                                    <th>Ora</th>
                                                    <td><h5><b><i className="mdi mdi-calendar-clock" />&nbsp;&nbsp;{moment(bookingDetail.date_hour).format('HH:mm')}</b></h5></td>
                                                </tr>
                                                <tr>
                                                    <th>Operatore</th>
                                                    <td><b><i className="mdi mdi-account" /> {bookingDetail.service.operator}</b></td>
                                                </tr>
                                                <tr>
                                                    <th>Sede</th>
                                                    <td>
                                                        <b><i className="mdi mdi-map" /> {bookingDetail.place.name}</b><br />
                                                        <i>{bookingDetail.place.address} - {bookingDetail.place.city}</i>
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </Table>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={6}>
                                    <Card>
                                        <CardBody>
                                            <h5 className="card-title">Informazioni Cliente</h5>
                                            <Table responsive striped>
                                                <tbody>
                                                <tr>
                                                    <th>Nome</th>
                                                    <td>
                                                        <a href="javascript:void(0)" onClick={() => history.push("/client/" + bookingDetail.client.id)} >
                                                            <h5 className="cursor-pointer text-primary">{bookingDetail.client.name}</h5>
                                                        </a>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>Cellulare</th>
                                                    <td>
                                                        {(bookingDetail.client.cell !== "") ? <>
                                                            <a href={"tel:" + bookingDetail.client.cell}>
                                                                <Badge style={{ fontSize: "12px" }} color="primary" className="m-1">
                                                                    <i className="mdi mdi-phone"></i> {bookingDetail.client.cell.slice(0, 3) + " " + bookingDetail.client.cell.slice(3)}
                                                                </Badge>
                                                            </a>&nbsp;&nbsp;
                                                            <a href={"https://api.whatsapp.com/send?phone=" + bookingDetail.client.cell.slice(1)}>
                                                                <Badge style={{ fontSize: "12px" }} color="success" className="m-1">
                                                                    <i className="mdi mdi-whatsapp"></i> Whatsapp
                                                                </Badge>
                                                            </a>
                                                        </> : null}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>Email</th>
                                                    <td>
                                                        {(bookingDetail.client.email !== "") ? <a href={"mail:" + bookingDetail.client.email}>
                                                            <Badge style={{ fontSize: "12px", marginTop: 8 }} color="secondary">
                                                                <i className="mdi mdi-email"></i>&nbsp;&nbsp;{bookingDetail.client.email}</Badge>
                                                        </a> : null}
                                                    </td>
                                                </tr>
                                                {bookingDetail.client.custom_fields.map((field, index) => (
                                                    <tr key={index}>
                                                        <th>{field.name}</th>
                                                        <td>
                                                            {field.type === 'PHONE' ? (
                                                                <a href={`tel:${field.value}`}>{field.value}</a>
                                                            ) : field.type === 'EMAIL' ? (
                                                                <a href={`mailto:${field.value}`}>{field.value}</a>
                                                            ) : (
                                                                field.value
                                                            )}
                                                        </td>
                                                    </tr>
                                                ))}
                                                </tbody>
                                            </Table>
                                        </CardBody>
                                    </Card>
                                </Col>
                                <Col lg={6}>
                                    <Card>
                                        <CardBody>
                                            <h5 className="card-title">Azioni</h5>
                                            <EditBooking onResolve={getBookingDetail} idBooking={bookingDetail.id} viewButton />
                                            <DeleteBooking id={bookingDetail.id} onResolve={() => history.replace("/booking")} viewButton/>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </React.Fragment>
                    )}
                </Container>
            </div>
        </React.Fragment>
    );
};

export default BookingDetail;
