import React, { Component } from 'react';
import { Card, CardBody, CardHeader, Col, Container, Row } from 'reactstrap';
import BreadCrumb from '../../Components/Common/BreadCrumb';
import {withRouter} from "react-router-dom";

import ServiceTable from "./ServiceTable";

class ServiceList extends Component {

    render() {
        document.title ="Servizi | " + process.env.REACT_APP_NAME;

        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        <BreadCrumb title="Servizi" pageTitle={process.env.REACT_APP_NAME}/>
                        <Row>
                            <Col lg={12}>
                                <Card>
                                    <CardBody>
                                        <ServiceTable />
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

export default withRouter(ServiceList);
