let server = process.env.REACT_APP_API_URL;

let frontendRoute = process.env.REACT_APP_FRONTEND_URL;

let productionMode = (process.env.REACT_APP_NODE_ENV === "production"); // valorizzare in TRUE per produzione
let versionApp = process.env.REACT_APP_VERSION; //Valorizzare per cambiare versione applicazione
let emailAssistance = process.env.REACT_APP_API_EMAIL_ASSISTANCE; //email assistenza
let route = {
  test: '/test',

  // dashboard
  dashboard: '/dashboard',
  dashboardModules: '/dashboard/modules',
  dashboardConsulents: '/dashboard/consulents',
  dashboardCounters: '/dashboard/counters',

  // geo service
  autocompleteGeo: '/geo/autocomplete',
  detailGeo: '/geo/detail',
  geoGps: '/geo/gps',

  // auth
  checkToken: '/token/check',
  permission: '/auth/permission',
  login: '/auth/signin',
  credentialEdit: '/auth/changepsw',
  resetPassword: '/auth/forgotpsw',
  completeResetPassword: '/auth/forgotpsw/reset',
  verifyOTP: '/auth/verifyotp',
  resendOTP: '/auth/verifyotp',
  logout: "/auth/logout",

  // company
  company: '/company',
  companySettings: '/company/settings',

  // admin
  admin: '/admin',

  // service
  service: "/service",
  serviceImage: "/service/image",

  // operator
  operator: "/operator",

  // place
  place: "/place",

  // close days
  closer_days: "/closer_days",

  // booking
  booking: "/booking/gest",
  bookingDetail: "/booking/detail",
  getAvailableDays: "/booking/gest/days",
  getAvailableSlot: "/booking/gest/slots",

  // consulent
  consulent: '/consulent',

  // manager
  manager: '/manager',

  // email
  email: '/email',
  emailSendTest: '/email/send/test',
  emailPresentation: '/email/send/presentation',

  // lead
  lead: '/lead',
  leadStatus: '/lead/status',
  leadClose: '/lead/close',
  leadlog: '/leadlog',
  leadUpload: '/lead/upload',
  leadAssign: '/lead/assign',

  // contact
  contact: '/contact',
  contactStatus: '/contact/status',
  contactClose: '/contact/close',
  contactlog: '/contactlog',
  contactAssign: '/contact/assign',

  contactCategoriesImport: '/contact/import/categories',
  contactCategories: '/contact/category',
  contactImport: '/contact/import',

  //appointment
  appointment: "/appointment",

  // copy
  copy: "/copy/email",

  //telemarketing
  telemarketing: "/telemarketing",
  telemarketingAppointment: "/telemarketing/appointment",

  // module
  module: '/module',
  moduleAssign: '/module/transfer',

  available: "/available",
  availableSlot: "/available/slot",
  availableDash: "/available/dash",

  //report
  reporter: "/reporter",
  report: "/report",

  // network
  network: "/network",

  // profile
  profile: "/profile",

  // user
  user: "/user",
  resendSignup: "/user/resendtoken",
  signup: "/user/signup",

  // client
  client: "/client",

  // referent
  referent: "/referent",

  // contract
  course: "/course",
  courseAvailable: "/available/course",

  // sell
  sell: "/sell",

  // streaming
  streaming: "/streaming",
  streamingRequest: "/streaming/request",
  streamingView: "/streaming/view",

  lesson: '/lesson',
  file: '/file',
  level: '/level',

  // payment
  payment: '/payment',
  paymentCheck: '/payment/check',

  // sender
  sender: "/sender",

  // category
  category: "/category",

  // product
  product: "/product"
};



for (var i in route) {
  route[i] = server + route[i];
}
global.server = server;
global.frontend = frontendRoute;
global.productionMode = productionMode;
global.versionApp = versionApp;
global.emailAssistance = emailAssistance;
global.route = route;
module.export = route;
