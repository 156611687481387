import React, { Component } from 'react';
import {Alert, Badge, Col, Container, Row} from 'reactstrap';
import BreadCrumb from '../../Components/Common/BreadCrumb';
import {withRouter, Link} from "react-router-dom";
import { Grid, _ } from 'gridjs-react';

import {GestOperator, DeleteOperator, Loading} from "custom";

import {OperatorAPI, PlaceAPI, ServiceAPI} from "api";


class OperatorTable extends Component {

    constructor(props) {
        super(props);
        this.state = {
            operators: [],
            services: [],
            places: [],
            loading: true,
            error: false
        };
    }

    componentDidMount() {
        this.getOperators();
    }

    getOperators() {
        OperatorAPI.list().then(async (operators) => {
            let services = await ServiceAPI.list();

            let places = await PlaceAPI.list();

            let operatorsParsed = operators.map((o) => {
                return {
                    id: o.id,
                    name: o.name,
                    services: o.services.map((s) => {
                        return {
                            value: s.id,
                            label: s.name
                        }
                    }),
                    place: o.place
                }
            })

            this.setState({loading: false, error: false, operators: operatorsParsed, places: places.map((s) => {
                    return {
                        value: s.id,
                        label: s.name + " - " + s.city + " " + s.address
                    }
                }), services: services.map((s) => {
                return {
                    value: s.id,
                    label: s.name
                }
            })});
        }).catch(() => {
            this.setState({error: true});
        })
    }


    render() {

        if (this.state.error) {
            return <React.Fragment>
                <Alert color="danger" >Si è verificato un errore durante il recupero della lista operatori.</Alert>
            </React.Fragment>
        }

        return (
            <React.Fragment>
                <div className="flex-shrink-0" style={{margin: 10}}>
                    <GestOperator onResolve={this.getOperators.bind(this)} places={this.state.places} services={this.state.services}/>
                </div>
                {(this.state.loading) ? <Loading /> :
                <Grid
                    data={this.state.operators}
                    columns={[
                        {
                            id: 'id',
                            name: '#',
                            formatter: (cell, row) => _(
                                <span className="text-nowrap">{cell}</span>
                            ),
                            width: "50px"
                        },
                        {
                            id: 'name',
                            name: 'Nome',
                            formatter: (cell, row) => _(<span className="text-nowrap"><b>{cell}</b></span>)
                        },
                        {
                            id: 'services',
                            name: 'Servizi',
                            formatter: (cell, row) => _(<span className="text-nowrap"><b>{cell.length}</b> serviz{(cell.length === 1) ? "io" : "i"}</span>)
                        },
                        {
                            id: 'place',
                            name: 'Sede',
                            formatter: (cell, row) => _(<span className="text-nowrap"><b>{cell.name}</b><br /><i>{cell.address} - {cell.city}</i></span>)
                        },
                        {
                            id: 'id',
                            name: '',
                            sort: false,
                            formatter: (cell, row, i) => _(<ul
                                className="list-inline hstack gap-2 mb-0">
                                <GestOperator preset={this.state.operators.find((c) => c.id === row.cells[0].data)} places={this.state.places} services={this.state.services} onResolve={this.getOperators.bind(this)} />
                                <DeleteOperator operator={{id: row.cells[0].data}} onResolve={this.getOperators.bind(this)} />
                            </ul>)
                        }
                    ]}
                    style={{table: {'white-space': 'nowrap'}}}
                    resizable={true}
                    search={true}
                    sort={true}
                    pagination={{ enabled: true, limit: 10}}
                    language={{
                        'search': {
                            'placeholder': '🔍 Cerca...'
                        },
                        'pagination': {
                            'previous': 'Indietro',
                            'next': 'Avanti',
                            'showing': 'Stai visualizzando da',
                            'results': 'operatori',
                            'to': 'a',
                            'of': 'di',
                        },
                        'noRecordsFound': 'Nessun operatore trovato',
                        'error': 'Si è verificato un errore non previsto',
                    }}
                />}
            </React.Fragment>
        );
    }
}

export default withRouter(OperatorTable);
