import React, { useState, useEffect, Fragment } from 'react';
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Row,
    Col,
    Label,
    FormFeedback,
    Input
} from 'reactstrap';
import {Chip, CircularProgress, Stepper, Step, StepLabel, Autocomplete, TextField} from '@mui/material';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import {BookingAPI, ClientAPI} from "api";
import { Utils } from "custom";
import moment from 'moment';

const AddBooking = ({ services, onResolve, selDay }) => {

    const [step, setStep] = useState(1);
    const [selectedDay, setSelectedDay] = useState(selDay);
    const [form, setForm] = useState({
        booking: {
            id_service: null,
            date_time: ''
        },
        client: {
            id: null,
            name: '',
            email: '',
            cell: ''
        }
    });
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({});
    const [modalOpen, setModalOpen] = useState(false);
    const [availableSlots, setAvailableSlots] = useState([]);
    const [clients, setClients] = useState([]);
    const [availableDays, setAvailableDays] = useState([]);
    const [customFields, setCustomFields] = useState([]);
    const [customFieldsData, setCustomFieldsData] = useState([]);
    const [colorScheme, setColorScheme] = useState(null);

    const user = Utils.getUserData();


    const toggleModal = () => {
        if (modalOpen) {
            closeModal();
        } else {
            resetForm();
            setModalOpen(true);
        }
    };


    const resetForm = () => {
        setForm({
            booking: {
                id_service: null,
                date_time: ''
            },
            client: {
                name: '',
                email: '',
                cell: ''
            }
        });
        setCustomFieldsData(() => [])
        setCustomFields(() => [])
        setSelectedDay(null);
        setErrors({});
        setStep(1);
    };

    const fetchClients = async () => {
        try {
            const clientList = await ClientAPI.list();
            setClients(clientList);
        } catch (error) {
            console.error('Errore durante il recupero dei clienti', error);
        }
    };


    useEffect(() => {
        if (form.booking.id_service && step === 2) {
            getAvailableDays(form.booking.id_service);
        }
    }, [form.booking.id_service, step]);


    const getAvailableDays = async (id_service) => {
        setLoading(true);
        try {
            const res = await BookingAPI.getDaysAvailable(id_service);
            setAvailableDays(res.days.map(day => moment(day).toDate()));
            setCustomFields(() => res.service.custom_fields)

            if (res.service.custom_fields.length > 0) {
                const initialCustomFormData = res.service.custom_fields.map((c) => {
                    return {
                        id: c.id,
                        value: ""
                    }
                });
                setCustomFieldsData(initialCustomFormData);
            }

        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    const handleClientSelect = (client) => {
        if (client) {
            setForm(prevState => ({
                ...prevState,
                client: {
                    id: client.id,
                    name: client.name,
                    email: client.email,
                    cell: client.cell
                }
            }));
        } else {
            setForm(prevState => ({
                ...prevState,
                client: {
                    ...prevState.client,
                    id: null
                }
            }));
        }
    };

    const getAvailableSlots = async (day) => {
        setLoading(true);
        try {
            const slots = await BookingAPI.getSlotsAvailable(moment(day).format("YYYY-MM-DD"), form.booking.id_service);
            setAvailableSlots(slots);
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    const changeForm = (key, value, section = 'booking') => {

        if (key === "cell") {
            if (value[0] === "+") {
                value = "+" + value.slice(1).replace(/\D/g, "");
            } else {
                value = value.replace(/\D/g, "");
            }

            if (value.length >= 3 && value[0] !== "+") {
                value = "+39" + value;
            }
        }


        setForm(prevState => ({
            ...prevState,
            [section]: {
                ...prevState[section],
                [key]: value
            }
        }));
    };


    const handleServiceSelect = (id_service) => {
        changeForm('id_service', id_service);
        setColorScheme(services.find(s => s.id === id_service)?.color_scheme);
        setStep(2);
    };


    const handleDaySelect = (day) => {
        setSelectedDay(day);
        getAvailableSlots(day);
        setStep(3);
    };


    const handleSlotSelect = (slot) => {
        changeForm('date_time', `${moment(selectedDay).format("YYYY-MM-DD")} ${slot}`);
        fetchClients();
        setStep(4);
    };


    const saveBooking = () => {
        const newErrors = {};

        if (!form.client.name) newErrors.client_name = "Il campo nome è obbligatorio";
        if (form.client.cell && !Utils.isValidCell(form.client.cell)) newErrors.client_cell = "Cellulare non valido";
        if (form.client.email && !Utils.isValidEmail(form.client.email)) newErrors.client_email = "Email non valida";
        if (!form.booking.id_service) newErrors.id_service = "Il servizio è obbligatorio";
        if (!form.booking.date_time) newErrors.date_time = "L'orario è obbligatorio";

        setErrors(newErrors);

        if (Object.keys(newErrors).length === 0) {
            setLoading(true);

            const requestData = {
                date_time: form.booking.date_time,
                client: {
                    id: form.client.id || null,
                    name: form.client.name,
                    email: form.client.email || '',
                    cell: form.client.cell || ''
                },
                custom_fields: customFieldsData
            };

            requestData.id_service = form.booking.id_service;

            BookingAPI.add(requestData)
                .then(() => {
                    setLoading(false);
                    global.SweetAlert.fire({
                        title: 'Prenotazione creata',
                        text: 'La prenotazione è stata creata correttamente.',
                        icon: 'success',
                        allowOutsideClick: false,
                        customClass: { confirmButton: 'btn btn-primary' },
                        confirmButtonText: 'Chiudi',
                        buttonsStyling: false
                    }).then(result => {
                        if (result.value) {
                            toggleModal();
                            onResolve();
                        }
                    });
                })
                .catch(err => {
                    setLoading(false);
                    global.SweetAlert.fire({
                        title: 'Errore',
                        text: 'Si è verificato un errore durante la gestione della prenotazione',
                        icon: 'error',
                        customClass: { confirmButton: 'btn btn-primary' }
                    });
                });
        }
    };

    const closeModal = () => {
        resetForm();
        setModalOpen(false);
    };


    const tileDisabled = ({ date, view }) => {
        if (view === 'month') {
            return !availableDays.find(d => moment(d).isSame(date, 'day'));
        }
    };

    const handleCustomFieldChange = (id, value) => {
        let param_custom = customFields.find((f) => f.id === id);

        if (!param_custom)
            return;

        if (param_custom.type === "PHONE") {
            if (value[0] === "+") {
                value = "+" + value.slice(1).replace(/\D/g, "");
            } else {
                value = value.replace(/\D/g, "");
            }

            if (value.length >= 3 && value[0] !== "+") {
                value = "+39" + value;
            }
        }

        setCustomFieldsData(prevState => {
            const fieldIndex = prevState.findIndex(item => item.id === id);
            if (fieldIndex !== -1) {
                const updatedFields = [...prevState];
                updatedFields[fieldIndex].value = value;
                return updatedFields;
            } else {
                return [...prevState, { id, value }];
            }
        });
    };

    const renderCustomField = (field) => {

        if (field.type === "SELECT")
            return (
                <Col md="12" className="mt-3">
                    <Label>{field.name}</Label>
                    <select
                        value={customFieldsData.find((c) => c.id === field.id)?.value}
                        onChange={(e) => handleCustomFieldChange(field.id, e.target.value)}
                    >
                        <option value="" disabled>Seleziona {field.name}</option>
                        {field.allowed_multiple_values && field.allowed_multiple_values.map((option, idx) => (
                            <option key={idx} value={option}>{option}</option>
                        ))}
                    </select>
                </Col>
            );


        return (
            <Col md="12" className="mt-3">
                <Label>{field.name}</Label>
                <Input
                    type={field.type.toLowerCase()}
                    value={customFieldsData.find((c) => c.id === field.id)?.value}
                    // invalid={Boolean(errors.client_email)}
                    onChange={(e) => handleCustomFieldChange(field.id, e.target.value)}
                />
                {/*<FormFeedback>{errors.client_email}</FormFeedback>*/}
            </Col>
        );
    };

    return (
        <Fragment>
            <Button color="success" size="sm" onClick={toggleModal}>
                <i className="ri-add-fill fs-10"></i> Nuova Prenotazione
            </Button>
            <Modal isOpen={modalOpen} toggle={toggleModal} size="lg">
                <ModalHeader toggle={toggleModal}>
                    Nuova Prenotazione
                </ModalHeader>
                <ModalBody>
                    <Stepper activeStep={step - 1} alternativeLabel>
                        <Step key="Servizio">
                            <StepLabel>Servizio</StepLabel>
                        </Step>
                        <Step key="Giorno">
                            <StepLabel>Giorno</StepLabel>
                        </Step>
                        <Step key="Slot">
                            <StepLabel>Slot Orario</StepLabel>
                        </Step>
                        <Step key="Cliente">
                            <StepLabel>Cliente</StepLabel>
                        </Step>
                    </Stepper>

                    {step === 1 && (
                        <div className="service-selection p-4 text-center">
                            <h5>Seleziona il Servizio</h5>
                            <Row className="justify-content-center text-center">
                                {services.map(service => (
                                    <Col key={service.id} md={12} className="mb-3">
                                        <Chip
                                            label={service.name}
                                            clickable
                                            onClick={() => handleServiceSelect(service.id)}
                                            style={{
                                                backgroundColor: service.color_scheme,
                                                color: 'white',
                                                fontSize: '18px',
                                                padding: '10px 20px',
                                                borderRadius: '8px'
                                            }}
                                        />
                                    </Col>
                                ))}
                            </Row>
                        </div>
                    )}

                    {step === 2 && (
                        <div className="justify-content-center day-selection p-4 text-center">
                            <h5>Seleziona il Giorno Disponibile</h5>
                            <Row className="justify-content-center">
                                <Calendar
                                    onChange={handleDaySelect}
                                    value={selectedDay}
                                    tileDisabled={tileDisabled}
                                    minDate={new Date()}
                                />
                            </Row>
                        </div>
                    )}

                    {step === 3 && (
                        <div className="slot-selection p-4 text-center">
                            <h5>Seleziona uno Slot Orario</h5>
                            <Row>
                                {loading ? (
                                    <CircularProgress />
                                ) : (
                                    availableSlots.map(slot => (
                                        <Col key={slot} md={4} className="mb-3">
                                            <Button
                                                block
                                                style={{
                                                    backgroundColor: colorScheme || '#5C68E2',
                                                    color: 'white',
                                                    fontSize: '18px',
                                                    padding: '10px 20px',
                                                    borderRadius: '8px'
                                                }}
                                                onClick={() => handleSlotSelect(slot)}
                                            >
                                                {slot}
                                            </Button>
                                        </Col>
                                    ))
                                )}
                            </Row>
                        </div>
                    )}

                    {step === 4 && (
                        <div className="client-details p-4">
                            <h5>Riepilogo Prenotazione</h5>
                            <div className="mb-4">
                                <p><strong>Servizio:</strong> {services.find(s => s.id === form.booking.id_service)?.name} ({services.find(s => s.id === form.booking.id_service)?.minutes_duration} minuti)</p>
                                <p><strong>Data e ora:</strong> {moment(form.booking.date_time).format('dddd DD MMMM YYYY')} alle {moment(form.booking.date_time).format('HH:mm')}</p>
                            </div>
                            <h5>Inserisci i Dati del Cliente</h5>
                            <Row>
                                <Col md="6">
                                    <Label>Nome*</Label>
                                    <Autocomplete
                                        options={clients}
                                        getOptionLabel={(option) => option.name || ""}
                                        onChange={(event, value) => handleClientSelect(value)}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                sx={{
                                                    '& .MuiInputBase-root': {
                                                        height: '38px'
                                                    }
                                                }}
                                                label=""
                                                value={form.client.name}
                                                onChange={(e) => {
                                                    const capitalizedValue = e.target.value
                                                        .split(' ')
                                                        .map(word => word
                                                            .split("'")
                                                            .map(part => part.charAt(0).toUpperCase() + part.slice(1).toLowerCase())
                                                            .join("'")
                                                        )
                                                        .join(' ');

                                                    changeForm('name', capitalizedValue, 'client');
                                                }}
                                                error={Boolean(errors.client_name)}
                                                helperText={errors.client_name}
                                            />
                                        )}
                                        freeSolo
                                        defaultValue={form.client.name || ""}
                                    />
                                    <FormFeedback>{errors.client_name}</FormFeedback>
                                </Col>
                                <Col md="6">
                                    <Label>Cellulare</Label>
                                    <Input
                                        type="text"
                                        value={form.client.cell}
                                        invalid={Boolean(errors.client_cell)}
                                        onChange={(e) => changeForm('cell', e.target.value, 'client')}
                                    />
                                    <FormFeedback>{errors.client_cell}</FormFeedback>
                                </Col>
                                <Col md="12" className="mt-3">
                                    <Label>Email</Label>
                                    <Input
                                        type="email"
                                        value={form.client.email}
                                        invalid={Boolean(errors.client_email)}
                                        onChange={(e) => changeForm('email', e.target.value, 'client')}
                                    />
                                    <FormFeedback>{errors.client_email}</FormFeedback>
                                </Col>
                            </Row>
                            <br /><br />
                            {customFields && customFields.map(field => renderCustomField(field))}
                        </div>
                    )}
                </ModalBody>
                <ModalFooter>
                    {step === 4 && (
                        <Button color="success" onClick={saveBooking} disabled={loading}>
                            Salva Prenotazione
                        </Button>
                    )}
                    <Button color="danger" onClick={step > 1 ? () => setStep(step - 1) : closeModal}>
                        {step === 1 ? "Chiudi" : "Indietro"}
                    </Button>
                </ModalFooter>
            </Modal>
        </Fragment>
    );
};

export default AddBooking;
