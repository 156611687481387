import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';

import "./routesAPI";

import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";

import { configureStore } from "./store";

const root = ReactDOM.createRoot(document.getElementById("root"));

document.addEventListener('gesturestart', function (e) {
    e.preventDefault();
});

if (process.env.REACT_APP_NODE_ENV === "production") {
    console.log('%cWelcome to Schedula PRO', 'color: #ff6f00; font-size: 24px; font-weight: bold; text-shadow: 2px 2px 4px #000000; background-color: #ffd54f; padding: 10px;');
    console.log('%cDeveloped by FB Web Studio', 'color: #4caf50; font-size: 18px; font-weight: bold;');

    document.addEventListener("contextmenu", function (e) {
        e.preventDefault();
    });
    console.log = function() {};
    console.error = function() {};
}

root.render(
    <Provider store={configureStore({})}>
      <React.Fragment>
        <BrowserRouter basename={process.env.PUBLIC_URL}>
          <App />
        </BrowserRouter>
      </React.Fragment>
    </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
