import React, { useState, useEffect } from 'react';
import {
    Card,
    CardBody,
    CardHeader,
    Col,
    Container,
    Row,
    Button,
    Input,
    Label,
    FormGroup,
    Spinner,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Alert
} from 'reactstrap';
import { CloseDayAPI, OperatorAPI } from 'api';
import moment from 'moment';

const CloseDaySection = () => {
    const [loading, setLoading] = useState(true);
    const [operators, setOperators] = useState([]);
    const [closerDays, setCloserDays] = useState([]);
    const [newCloseDay, setNewCloseDay] = useState({});
    const [selectedOperator, setSelectedOperator] = useState(null);
    const [error, setError] = useState(null);
    const [errorAdd, setErrorAdd] = useState(null);
    const [modal, setModal] = useState(false);
    const [createModal, setCreateModal] = useState(false);
    const [deleteCloseDayId, setDeleteCloseDayId] = useState(null);

    useEffect(() => {
        loadOperators();
    }, []);

    const loadOperators = async () => {
        setLoading(true);
        try {
            const operatorsData = await OperatorAPI.list();
            setOperators(operatorsData);
            loadCloserDays();
        } catch (error) {
            console.error("Error loading operators:", error);
            setLoading(false);
        }
    };

    const loadCloserDays = async () => {
        try {
            const closerDaysData = await CloseDayAPI.list();
            setCloserDays(closerDaysData);
            setLoading(false);
        } catch (error) {
            console.error("Error loading closer days:", error);
            setLoading(false);
        }
    };

    const toggleModal = (closeDayId = null) => {
        setDeleteCloseDayId(closeDayId);
        setModal(!modal);
    };

    const toggleCreateModal = () => {
        setCreateModal(!createModal);
        setErrorAdd(null);
    };

    const handleAddCloseDay = () => {
        if (newCloseDay.date_start && newCloseDay.date_end && selectedOperator) {
            CloseDayAPI.add({ ...newCloseDay, operator_id: selectedOperator })
                .then(() => {
                    loadCloserDays();
                    setNewCloseDay({});
                    setErrorAdd(null);
                    toggleCreateModal();
                })
                .catch(error => {
                    console.error("Error adding close day:", error);
                    setErrorAdd("Errore nell'aggiungere il periodo di chiusura.");
                });
        } else {
            setErrorAdd("Compila tutti i campi per aggiungere un periodo di chiusura.");
        }
    };

    const handleEditCloseDay = (closeDayId, updatedCloseDay) => {
        CloseDayAPI.edit({ id: closeDayId, ...updatedCloseDay })
            .then(() => {
                loadCloserDays();
                setError(null);
            })
            .catch(error => {
                console.error("Error editing close day:", error);
                setError("Errore nella modifica del periodo di chiusura.");
            });
    };

    const handleDeleteCloseDay = () => {
        if (deleteCloseDayId) {
            CloseDayAPI.remove(deleteCloseDayId)
                .then(() => {
                    loadCloserDays();
                    toggleModal();
                    setError(null);
                })
                .catch(error => {
                    console.error("Error removing close day:", error);
                    setError("Errore nella rimozione del periodo di chiusura.");
                });
        }
    };

    const renderOperatorCards = () => {
        return operators.map(operator => (
            <Col md={12} key={operator.id} className="mb-3">
                <Card className="w-100">
                    <CardHeader>
                        <h5>{operator.name}</h5>
                    </CardHeader>
                    <CardBody>
                        {(closerDays.length === 0) ? <p>Nessun periodo di chiusura trovato per l'operatore {operator.name}</p> : null}
                        {closerDays.filter(day => day.operator.id === operator.id).map(closeDay => (
                            <div key={closeDay.id} className="mb-2">
                                <FormGroup className="d-flex flex-column flex-md-row justify-content-between">
                                    <div className="flex-grow-1 mr-2 p-1">
                                        <Label for={`date_start-${closeDay.id}`}>Da</Label>
                                        <Input
                                            type="date"
                                            id={`date_start-${closeDay.id}`}
                                            value={moment(closeDay.date_start).format('YYYY-MM-DD')}
                                            onChange={(e) =>
                                                handleEditCloseDay(closeDay.id, {
                                                    ...closeDay,
                                                    date_start: e.target.value
                                                })
                                            }
                                        />
                                    </div>
                                    <div className="flex-grow-1 mr-2 p-1">
                                        <Label for={`date_end-${closeDay.id}`}>A</Label>
                                        <Input
                                            type="date"
                                            id={`date_end-${closeDay.id}`}
                                            value={moment(closeDay.date_end).format('YYYY-MM-DD')}
                                            onChange={(e) =>
                                                handleEditCloseDay(closeDay.id, {
                                                    ...closeDay,
                                                    date_end: e.target.value
                                                })
                                            }
                                        />
                                    </div>
                                    <div className="d-flex align-items-center" style={{ marginTop: 25 }}>
                                        <Button color="danger" size="sm" onClick={() => toggleModal(closeDay.id)}>
                                            <i className="ri-delete-bin-line"></i>
                                        </Button>
                                    </div>
                                    <hr />
                                </FormGroup>
                            </div>
                        ))}
                        <Button color="success" size="sm" onClick={() => {
                            setSelectedOperator(operator.id);
                            toggleCreateModal();
                        }}>
                            <i className="ri-add-fill"></i> Aggiungi Periodo di Chiusura
                        </Button>
                    </CardBody>
                </Card>
            </Col>
        ));
    };

    return (
        <Container fluid>
            <Row>
                <Col md={12} className="p-3">
                    <h4>Gestione Giorni di Chiusura Operatori</h4>
                    <p>Seleziona i giorni di chiusura per ogni operatore.</p>
                    {error && <Alert color="danger">{error}</Alert>}
                    {loading ? <Spinner color="primary" /> : <Row>{renderOperatorCards()}</Row>}
                </Col>
            </Row>

            <Modal isOpen={modal} toggle={toggleModal}>
                <ModalHeader toggle={toggleModal}>Conferma Rimozione</ModalHeader>
                <ModalBody>Sei sicuro di voler rimuovere questo periodo di chiusura?</ModalBody>
                <ModalFooter>
                    <Button color="danger" onClick={handleDeleteCloseDay}>Conferma</Button>{' '}
                    <Button color="secondary" onClick={toggleModal}>Annulla</Button>
                </ModalFooter>
            </Modal>

            <Modal isOpen={createModal} toggle={toggleCreateModal}>
                <ModalHeader toggle={toggleCreateModal}>Aggiungi Periodo di Chiusura</ModalHeader>
                <ModalBody>
                    {errorAdd && <Alert color="danger">{errorAdd}</Alert>}
                    <FormGroup>
                        <Label for="new-close-day-start">Da</Label>
                        <Input
                            type="date"
                            id="new-close-day-start"
                            value={newCloseDay.date_start || ''}
                            onChange={(e) => setNewCloseDay({ ...newCloseDay, date_start: e.target.value })}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label for="new-close-day-end">A</Label>
                        <Input
                            type="date"
                            id="new-close-day-end"
                            value={newCloseDay.date_end || ''}
                            onChange={(e) => setNewCloseDay({ ...newCloseDay, date_end: e.target.value })}
                        />
                    </FormGroup>
                </ModalBody>
                <ModalFooter>
                    <Button color="success" onClick={handleAddCloseDay}>Conferma</Button>{' '}
                    <Button color="danger" onClick={toggleCreateModal}>Annulla</Button>
                </ModalFooter>
            </Modal>
        </Container>
    );
};

export default CloseDaySection;
