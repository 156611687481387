import React, { useEffect, useState } from 'react';
import {
    Alert,
    Card,
    CardBody,
    CardHeader,
    Col,
    Container,
    Row
} from 'reactstrap';
import BreadCrumb from '../../Components/Common/BreadCrumb';
import { BookingAPI, OperatorAPI, ServiceAPI } from 'api';

import { Utils } from 'custom';

import {Skeleton} from "@mui/material";

import BookingTabDay from "./Tab/BookingTabDay";
import BookingTabMonth from "./Tab/BookingTabMonth";
import {useLocation} from "react-router-dom";

const moment = require("moment");

const BookingMain = () => {

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [selectedDay, setSelectedDay] = useState(null);
    const [services, setServices] = useState([]);
    const [operators, setOperators] = useState([]);
    const [bookings, setBookings] = useState([]);
    const [daysRange, setDaysRange] = useState({
        startDay: moment().clone().startOf('month').toISOString(),
        endDay: moment().clone().endOf('month').toISOString()
    });

    const location = useLocation();

    useEffect(() => {
        if (Utils.getUserData().role !== "COMPANY") {
            window.location.replace('/company');
            return;
        }

        getOperators();
        getServices();
    }, []);

    useEffect(() => {
        if (Utils.getUserData().role !== "COMPANY") {
            return;
        }
        getBookings();
    }, [daysRange]);

    let getOperators = async () => {
        try {
            let resOperators = await OperatorAPI.list();
            if (resOperators) setOperators(resOperators);
        } catch (e) {
            setError(true);
        }
    };

    let getServices = async () => {
        try {
            let resServices = await ServiceAPI.list();
            if (resServices) setServices(resServices);
        } catch (e) {
            setError(true);
        }
    };

    let getBookings = async () => {
        try {
            let resBookings = await BookingAPI.list(moment(daysRange.startDay).format("YYYY-MM-DD"), moment(daysRange.endDay).format("YYYY-MM-DD"));
            if (resBookings) setBookings(resBookings);

            setLoading(false);
        } catch (e) {
            setError(true);
        }
    };

    if (error)
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        <BreadCrumb title="Prenotazioni" pageTitle={process.env.REACT_APP_NAME} />
                        <Row>
                            <Col lg={12}>
                                <Alert color="danger">Si è verificato un errore durante il recupero delle prenotazioni</Alert>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        );

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title="Prenotazioni" pageTitle={process.env.REACT_APP_NAME} />
                    <Row>
                        <Col lg={12}>
                            <Row className={"mt-3"}>
                                <Col md={12} lg={12} xl={12}>
                                    <Card>
                                        <CardHeader>
                                            <h5>Calendario</h5>
                                        </CardHeader>
                                        <CardBody>
                                            {(loading) ? <Skeleton variant="rectangular" height="200px" width="100%" /> : <BookingTabMonth setDaysRange={setDaysRange} bookings={bookings} selectedDay={selectedDay} setSelectedDay={setSelectedDay} services={services} />}
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12} lg={12} xl={12}>
                                    <Card>
                                        <CardHeader>
                                            <h5>Prenotazioni</h5>
                                        </CardHeader>
                                        <CardBody>
                                            {(loading) ? <Skeleton variant="rectangular" height="200px" width="100%" /> : <BookingTabDay selectedDay={selectedDay} services={services} bookings={bookings} onReloadBookings={getBookings}/>}
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
}

export default BookingMain;
