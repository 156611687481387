import React, { Component } from 'react';
import {
    Alert,
    Badge,
    Card,
    CardHeader,
    CardBody,
    Col,
    Container,
    Row,
    Nav,
    NavItem,
    NavLink,
    TabPane,
    TabContent,
    Table,
    Button
} from 'reactstrap';
import {withRouter, Redirect, Link} from "react-router-dom";

import {ClientAPI, CompanyAPI} from "api";

import {Loading, Utils} from "custom";

import profileBg from '../../assets/images/monitor-bg.jpg';
import avatarClient from '../../assets/images/avatar/client.jpg';

import TabAnag from "./TabsGest/TabAnag";
import TabAdmin from "./TabsGest/TabAdmin";

const moment = require("moment");

class CompanyDetail extends Component {

    constructor(props) {
        super(props);
        const query = new URLSearchParams(window.location.search)
        this.state = {
            loading: true,
            error: false,
            company: null,
            tabActive: 1
        };
        document.title ="Dettagli Azienda | " + process.env.REACT_APP_NAME;
    }

    componentDidMount() {
        this.getDetailCompany()
    }

    getDetailCompany() {
        CompanyAPI.detail(this.props.match.params.id).then((company) => {
            this.setState({company: company, loading: false})
        }).catch((err) => {
            console.error(err);
            this.setState({error: true})
        })
    }

    // deleteClient() {
    //     const self = this;
    //     global.SweetAlert.fire({
    //         title: "Elimina cliente",
    //         text: 'Attenzione, stai eliminando un cliente, questo comporterà l\'eliminazione di tutti i contratti, campagne e dati associati ad esso. Vuoi procedere??',
    //         icon: 'warning',
    //         showCancelButton: true,
    //         confirmButtonText: 'Si, elimina',
    //         cancelButtonText: 'Annulla',
    //         customClass: {
    //             confirmButton: 'btn btn-danger',
    //             cancelButton: 'btn btn-outline-danger ms-1'
    //         },
    //         buttonsStyling: false,
    //         showLoaderOnConfirm: true,
    //         preConfirm: function() {
    //             return new Promise(function(resolve, reject) {
    //                 ClientAPI.remove(self.state.client.id).then(() => {
    //                     resolve();
    //                 }).catch(() => {
    //                     global.SweetAlert.fire({
    //                         icon: 'danger',
    //                         title: 'Si è verificato un errore :(',
    //                         text: 'Purtroppo qualcosa è andato storto e non è stato possibile eliminare il cliente. Riprova più tardi.',
    //                         customClass: {
    //                             confirmButton: 'btn btn-success'
    //                         }
    //                     });
    //                     reject()
    //                 })
    //             });
    //         },
    //     }).then(function (result) {
    //         if (result.value) {
    //             global.SweetAlert.fire({
    //                 icon: 'success',
    //                 title: 'Cliente eliminato!',
    //                 text: 'Cliente eliminato correttamente.',
    //                 customClass: {
    //                     confirmButton: 'btn btn-success'
    //                 }
    //             });
    //             window.location.replace("/client");
    //         }
    //     })
    // }


    render() {

        if (this.state.error) {
            return <React.Fragment>
                <div className="page-content">
                    <Alert color="danger" >Si è verificato un errore durante il recupero dei dettagli dell'azienda.</Alert>
                </div>
            </React.Fragment>
        }

        const company = this.state.company;

        return (
            <React.Fragment>
                {(this.state.loading) ? <Loading /> : <div className="page-content">
                    <Container fluid>
                        <div className="profile-foreground position-relative mx-n4 mt-n4">
                            <div className="profile-wid-bg">
                                <img src={profileBg} alt="" className="profile-wid-img" />
                            </div>
                        </div>
                        <div className="pt-4 mb-4 mb-lg-3 pb-lg-4">
                            <Row className="g-4">
                                <div className="col-auto">
                                    <div className="avatar-lg">
                                        <img src={(company.logo === null) ? avatarClient : company.logo} alt="user-img"
                                             className="img-thumbnail rounded-circle" />
                                    </div>
                                </div>

                                <Col>
                                    <Link to={"/company"}><Button className="btn btn-sm btn-info" ><i className="ri-arrow-left-circle-line fs-5 text-white-75 align-middle" /> Torna alla lista</Button></Link>
                                    <div className="p-2">
                                        <h3 className="text-white mb-1">{company.name}</h3>
                                        <p className="text-white-75">Azienda</p>
                                        <div className="hstack text-white-50 gap-1">
                                            <div className="me-2">
                                                <i className="ri-map-pin-user-line me-1 text-white-75 fs-16 align-middle"></i>{company.full_address}
                                            </div>
                                        </div>
                                        <div className="hstack text-white-50 gap-1">
                                            <div className="me-2">
                                                <i className="ri-calendar-2-fill me-1 text-white-75 fs-16 align-middle"></i>Creato il: {moment(company.createdAt).format("DD/MM/YYYY")}
                                            </div>
                                        </div>
                                    </div>
                                </Col>

                                <Col xs={12} className="col-lg-auto order-last order-lg-0">
                                    <Row className="text text-white-50 text-center">
                                        <Col lg={6} xs={3}>
                                            <div className="p-2">
                                                <h5 className="text-white mb-1">Stato</h5>
                                                {(company.active) ? <Badge color="success" pill>Attivo</Badge> : <Badge color="danger" pill>Non Attivo</Badge>}
                                            </div>
                                        </Col>
                                        <Col lg={6} xs={3}>
                                            <div className="p-2">
                                                <h5 className="text-white mb-1">ID</h5>
                                                <p className="fs-16 mb-0">{company.id}</p>
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </div>

                        <Row>
                            <Col lg={12}>
                                <div>
                                    <div className="d-flex">
                                        <Nav pills className="animation-nav profile-nav gap-2 gap-lg-3 flex-grow-1" role="tablist">
                                            <NavItem>
                                                <NavLink
                                                    className={ "cursor-pointer " + ((this.state.tabActive === 1) ? "fs-16 bg-white text-dark" : null)}
                                                    onClick={() => this.setState({tabActive: 1}) }
                                                >
                                                    <i className="ri-book-2-fill d-inline-block d-md-none"></i> <span
                                                    className="d-none d-md-inline-block">Anagrafica</span>
                                                </NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink
                                                    className={ "cursor-pointer " + ((this.state.tabActive === 2) ? "fs-16 bg-white text-dark" : null)}
                                                    onClick={() => this.setState({tabActive: 2}) }
                                                >
                                                    <i className="ri-admin-fill d-inline-block d-md-none"></i> <span
                                                    className="d-none d-md-inline-block">Amministratori</span>
                                                </NavLink>
                                            </NavItem>
                                        </Nav>
                                    </div>

                                    <TabContent activeTab={String(this.state.tabActive)} className="pt-4">
                                        <TabPane tabId="1">
                                            <TabAnag company={company} />

                                        </TabPane>
                                        <TabPane tabId="2">
                                            <TabAdmin company={company} history={this.props.history}/>
                                        </TabPane>
                                    </TabContent>

                                </div>
                            </Col>
                        </Row>

                    </Container>
                </div>}
            </React.Fragment>
        );
    }
}

export default withRouter(CompanyDetail);
