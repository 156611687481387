import React from "react";
import {Link, Redirect} from "react-router-dom";

import Unauthorized from "../pages/Errors/Unauthorized";
import Offlinepage from "../pages/Errors/Offlinepage";
import Error500 from "../pages/Errors/Error500";

import RedirectPage from "../pages/Authentication/RedirectPage";

import Login from "../pages/Authentication/Login";

import { Utils } from "custom";

import CompanyList from "../pages/Company/CompanyList";
import CompanyDetail from "../pages/Company/CompanyDetail";

import AdministratorList from "../pages/Administrator/AdministratorList";

import ClientList from "../pages/Client/ClientList";
import ClientDetail from "../pages/Client/ClientDetail";

import ServiceList from "../pages/Service/ServiceList";
import OperatorList from "../pages/Operator/OperatorList";

import AvailableMain from "../pages/Available/AvailableMain";



import BookingMain from "../pages/Booking/BookingMain";

import BookingDetail from "../pages/Booking/BookingDetail";


import PlaceList from "../pages/Place/PlaceList";

import Settings from "../pages/Settings/Settings";


const authProtectedRoutes = [

  { path: "/company", auth: ["ADMIN"], component: CompanyList },
  { path: "/company/:id", auth: ["ADMIN"], component: CompanyDetail },

  { path: "/admin", auth: ["ADMIN"], component: AdministratorList },

  { path: "/booking", auth: ["COMPANY", "ADMIN"], component: BookingMain },

  { path: "/booking/:id_booking", auth: ["COMPANY"], component: BookingDetail },

  { path: "/service", auth: ["COMPANY"], component: ServiceList },

  { path: "/places", auth: ["COMPANY"], component: PlaceList },

  { path: "/operator", auth: ["COMPANY"], component: OperatorList },

  { path: "/client", auth: ["COMPANY"], component: ClientList },

  { path: "/client/:id", auth: ["COMPANY"], component: ClientDetail },

  { path: "/available", auth: ["COMPANY"], component: AvailableMain },


  { path: "/settings", auth: ["COMPANY"], component: Settings },

  {
    path: "/",
    auth: ["ADMIN", "COMPANY"],
    exact: true,
    component: () => <Redirect to={"/booking"} />,
  }
];

const publicRoutes = [
  { path: "/redirect", component: RedirectPage },
  { path: "/login", component: Login },
  { path: "/offline", component: Offlinepage },
  { path: "/unauthorized", component: Unauthorized },
  { path: "/server/error", component: Error500 }
];

export { authProtectedRoutes, publicRoutes };
