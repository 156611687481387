
let list = function() {
    return new Promise(function(resolve, reject) {
        const hash = localStorage.getItem('hash');
        const company = localStorage.getItem('companySelected');

        fetch(global.route.appointment, {
            headers: {
                'x-access-token': hash,
                'x-access-company': company
            },
            method: 'GET'
        })
            .then((response) => (response.status !== 500) ? response.json() : reject())
            .then((responseJson) => {
                global.checkAuth(responseJson.status);
                console.log('appointments list', responseJson);
                if (responseJson.status === "LIST_APPOINTMENT")
                    resolve(responseJson.list);
                else
                    reject(responseJson);
            })
            .catch((e) => {
                console.error(e.message);
                reject('APP_ERROR');
            });
    });
};

let edit = function(payload) {
    return new Promise(function(resolve, reject) {
        const hash = localStorage.getItem('hash');
        const company = localStorage.getItem('companySelected');

        fetch(global.route.available, {
            headers: {
                'x-access-token': hash,
                'x-access-company': company,
                'Content-Type': 'application/json'
            },
            method: 'POST',
            body: JSON.stringify({
                id: payload.id,
                available_from: payload.available_from,
                available_to: payload.available_to
            })
        })
            .then((response) => (response.status !== 500) ? response.json() : reject())
            .then((responseJson) => {
                global.checkAuth(responseJson.status);
                console.log('available edit', responseJson);
                if (responseJson.status === "AVAILABLE_EDITED")
                    resolve(responseJson);
                else
                    reject(responseJson);
            })
            .catch((e) => {
                console.error(e.message);
                reject('APP_ERROR');
            });
    });
};

let remove = function(id) {
    return new Promise(function(resolve, reject) {
        const hash = localStorage.getItem('hash');
        const company = localStorage.getItem('companySelected');

        fetch(global.route.available, {
            headers: {
                'x-access-token': hash,
                'x-access-company': company,
                'Content-Type': 'application/json'
            },
            method: 'DELETE',
            body: JSON.stringify({
                id: id
            })
        })
            .then((response) => (response.status !== 500) ? response.json() : reject())
            .then((responseJson) => {
                global.checkAuth(responseJson.status);
                console.log('available delete', responseJson);
                if (responseJson.status === "AVAILABLE_DELETED")
                    resolve(responseJson);
                else
                    reject(responseJson);
            })
            .catch((e) => {
                console.error(e.message);
                reject('APP_ERROR');
            });
    });
};

const AppointmentAPI = {
    list: list,
    edit: edit,
    remove: remove,
};

module.exports = AppointmentAPI;
