import React, { useEffect, useState } from 'react';
import { _, Grid } from "gridjs-react";
import { Badge } from "reactstrap";
import moment from 'moment';
import '../../../assets/scss/BookingTabMonth.css';

import {DeleteBooking, AddBooking, EditBooking, Params} from "custom";
import {useHistory} from "react-router-dom";


const BookingTabDay = ({ selectedDay, bookings, services, onReloadBookings }) => {
    const [bookingsFiltered, setBookingsFiltered] = useState([]);
    const [showPastBookings, setShowPastBookings] = useState(false);
    const [showPastButton, setShowPastButton] = useState(false);

    const history = useHistory()

    useEffect(() => {
        filterBookings();
    }, [selectedDay, bookings, showPastBookings]);

    useEffect(() => {
        return () => {
            Params.removeHandlePaginator()
        };
    }, [])

    const filterBookings = () => {
        if (!selectedDay) return;

        const now = moment();
        const selectedMoment = moment(selectedDay);

        let filteredBookings = bookings.filter(booking =>
            moment(booking.date_start).isSame(selectedMoment, 'day')
        );

        if (selectedMoment.isSame(now, 'day')) {
            const hasPastBookings = filteredBookings.some(booking =>
                moment(booking.date_start).isBefore(now)
            );

            setShowPastButton(hasPastBookings);
            if (!showPastBookings) {
                filteredBookings = filteredBookings.filter(booking =>
                    moment(booking.date_start).isAfter(now)
                );
            }
        } else {
            setShowPastButton(false);
        }
        setBookingsFiltered(filteredBookings);
        setTimeout(() => {
            Params.addHandlePaginator()
        }, 1000)
    };


    const handleShowPastBookings = () => {
        setShowPastBookings(!showPastBookings);
    };

    if (!selectedDay) {
        return (
            <React.Fragment>
                <p>Seleziona un giorno per vedere le prenotazioni.</p>
            </React.Fragment>
        );
    }

    return (
        <React.Fragment>
            <h6 className="m-2">Prenotazioni per il giorno</h6>
            <h5 className="m-2">{moment(selectedDay, "YYYY-MM-DD").format("dddd DD MMMM YYYY")}</h5>
            <div className="mt-4 mb-4">
                <AddBooking selDay={selectedDay} onResolve={onReloadBookings} services={services}/>
            </div>
            {bookingsFiltered.length !== bookings.length && (
                <h6 className="text-center text-primary cursor-pointer" hidden={!(showPastButton)} onClick={handleShowPastBookings}>
                    <b>{showPastBookings ? 'Nascondi prenotazioni passate' : 'Visualizza prenotazioni passate'}</b>
                </h6>
            )}
            <Grid
                data={bookingsFiltered}
                columns={[
                    {
                        id: 'id',
                        hidden: true
                    },
                    {
                        id: 'operator',
                        hidden: true
                    },
                    {
                        id: 'origin',
                        hidden: true
                    },
                    {
                        id: 'date_start',
                        name: 'Ora',
                        formatter: (cell, row) => _(<span className="text-nowrap">
                            <a href="javascript:void(0)" onClick={() => history.push("/booking/" + row.cells[0].data)}>
                                <h5 className="cursor-pointer"><i className="mdi mdi-calendar-clock" />&nbsp;&nbsp;{moment(cell).format("HH:mm")}</h5>
                            </a>
                            <p className="text-warning" hidden={row.cells[2].data !== "ONLINE"}><i className="mdi mdi-web" />&nbsp;Prenotazione Online</p>
                        </span>)
                    },
                    {
                        id: 'client',
                        name: 'Nome',
                        formatter: (cell, row) => _(<span className="mb-5 text-left">
                            <a href="javascript:void(0)" onClick={() => history.push("/booking/" + row.cells[0].data)}>
                                <h5 className="text-primary cursor-pointer"><b>{cell.name}</b></h5>
                            </a>
                            {(cell.cell !== "") ? <><a href={"https://api.whatsapp.com/send?phone=" + cell.cell.slice(1)}><Badge
                                style={{ fontSize: "12px" }} color="success"><i
                                className="mdi mdi-whatsapp"></i> Whatsapp</Badge></a>&nbsp;&nbsp;
                            <a href={"tel:" + cell.cell}><Badge
                                style={{ fontSize: "12px" }} color="primary"><i
                                className="mdi mdi-phone"></i> {cell.cell.slice(0, 3) + " " + cell.cell.slice(3)}</Badge></a><br /></> : null}

                            {(cell.email !== "") ? <a href={"mail:" + cell.email}><Badge
                                style={{ fontSize: "12px", marginTop: 8 }} color="secondary"><i
                                className="mdi mdi-email"></i>&nbsp;&nbsp;{cell.email}</Badge></a> : null}
                        </span>)
                    },
                    {
                        id: 'service',
                        name: 'Servizio',
                        formatter: (cell, row) => _(<div>
                            <span className="service-legend-item" style={{ backgroundColor: cell.color_scheme, color: 'white', padding: '3px 6px', borderRadius: '4px' }}>
                                {cell.name}
                            </span><br />
                            <h5 className="mt-2"><i className="mdi mdi-account" /> {row.cells[1].data.name}</h5>
                        </div>)
                    },
                    {
                        id: 'custom_fields',
                        name: 'Dettagli',
                        formatter: (cell, row) => _(<div>
                            {cell.map((c, i) => {
                                return <span key={i}>
                                    <small>
                                        {c.name.toUpperCase()}: <b>{c.value}</b>
                                    </small><br />
                                </span>
                            })}
                        </div>)
                    },
                    {
                        id: 'id',
                        name: '',
                        sort: false,
                        formatter: (cell, row, i) => _(<ul
                            className="list-inline hstack gap-2 mb-0">
                            <EditBooking idBooking={cell} onResolve={onReloadBookings} />
                            <DeleteBooking id={cell} onResolve={onReloadBookings}/>
                        </ul>)
                    }
                ]}
                style={{ table: { 'white-space': 'nowrap' } }}
                resizable={true}
                search={true}
                sort={true}
                pagination={{ enabled: true, limit: 25, page: Params.getActualPage() }}
                language={{
                    'search': {
                        'placeholder': '🔍 Cerca...'
                    },
                    'pagination': {
                        'previous': 'Indietro',
                        'next': 'Avanti',
                        'showing': 'Stai visualizzando da',
                        'results': 'prenotazioni',
                        'to': 'a',
                        'of': 'di',
                    },
                    'noRecordsFound': 'Nessuna prenotazione trovata',
                    'error': 'Si è verificato un errore non previsto',
                }}
            />
        </React.Fragment>
    );
}

export default BookingTabDay;
