let list = function() {
    return new Promise(function(resolve, reject) {
        const hash = localStorage.getItem('hash');
        fetch(global.route.user, {
            headers: {'x-access-token': hash},
            method: 'GET'
        })
            .then((response) => (response.status !== 500) ? response.json() : reject())
            .then((responseJson) => {
                global.checkAuth(responseJson.status);
                console.log('user list', responseJson);
                if (responseJson.status === "LIST_USERS")
                    resolve(responseJson.list);
                else
                    reject(responseJson);
            })
            .catch((e) => {
                console.error(e.message);
                reject('APP_ERROR');
            });
    });
};

let completeSignup = function (user, token) {
    return new Promise(function (resolve, reject) {
        fetch(global.route.signup + '?token=' + token, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                firstname: user.firstname,
                lastname: user.lastname,
                email: user.email,
                cell: user.cell,
                password: user.password
            })
        })
            .then((response) => (response.status !== 500) ? response.json() : console.log(response))
            .then((responseJson) => {
                if (responseJson.status === "USER_SIGNUP_OK")
                    resolve(responseJson);
                else
                    reject(responseJson);
            })
            .catch((e) => {
                console.error(e.message);
                window.location.assign('/server/error');
            });
    });
};

let getProfile = function() {
    return new Promise(function(resolve, reject) {
        const hash = localStorage.getItem('hash');
        fetch(global.route.profile, {
            headers: {'x-access-token': hash},
            method: 'GET'
        })
            .then((response) => (response.status !== 500) ? response.json() : reject())
            .then((responseJson) => {
                global.checkAuth(responseJson.status);
                console.log('profile detail', responseJson);
                if (responseJson.status === "PROFILE_DETAIL")
                    resolve(responseJson);
                else
                    reject(responseJson);
            })
            .catch((e) => {
                console.error(e.message);
                reject('APP_ERROR');
            });
    });
};

let editProfile = function (profile) {
    return new Promise(function (resolve, reject) {
        const hash = localStorage.getItem('hash');

        fetch(global.route.profile, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'x-access-token': hash
            },
            body: JSON.stringify({
                email: profile.email,
                firstname: profile.firstname,
                lastname: profile.lastname,
                cell: profile.cell
            })
        })
            .then((response) => (response.status !== 500) ? response.json() : reject())
            .then((responseJson) => {
                global.checkAuth(responseJson.status);
                if (responseJson.status === "PROFILE_EDITED")
                    resolve(responseJson.user);
                else
                    reject(responseJson.message);
            })
            .catch((e) => {
                console.error(e.message);
                reject('APP_ERROR');
            });
    });
};

let resendSignup = function(id) {
    return new Promise(function(resolve, reject) {
        const hash = localStorage.getItem('hash');
        const company = localStorage.getItem('companySelected');

        fetch(global.route.resendSignup, {
            headers: {
                'x-access-token': hash,
                'x-access-company': company,
                'Content-Type': 'application/json'
            },
            method: 'POST',
            body: JSON.stringify({
                id: id
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {
                global.checkAuth(responseJson.status);
                console.log('user resendSignup', responseJson);
                if (responseJson.status === "EMAIL_SENT")
                    resolve(responseJson.user);
                else
                    reject(responseJson);
            })
            .catch((e) => {
                console.error(e.message);
                reject('APP_ERROR');
            });
    });
};

const UserAPI = {
    list: list,
    getProfile: getProfile,
    editProfile: editProfile,
    resendSignup: resendSignup,
    completeSignup: completeSignup
};

module.exports = UserAPI;
