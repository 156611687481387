import React, { Component } from 'react';
import {Alert, Badge, Card, CardHeader, CardBody, Col, Container, Row, Nav, NavItem, NavLink, TabPane, TabContent, Table} from 'reactstrap';
import {withRouter, Link} from "react-router-dom";

import {ListAdmin} from "custom";


class TabAdmin extends Component {

    render() {

        const company = this.props.company;

        return (
            <React.Fragment>
                <Row>
                    <Col xxl={12}>
                        <Card>
                            <CardHeader>
                                <h5 className="card-title mb-5 d-md-none">Amministratori</h5>
                            </CardHeader>
                            <CardBody>
                                <ListAdmin id_company={company.id} history={this.props.history}/>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </React.Fragment>
        );
    }
}

export default withRouter(TabAdmin);
