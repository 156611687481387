let list = function() {
    return new Promise(function(resolve, reject) {
        const hash = localStorage.getItem('hash');

        fetch(global.route.closer_days, {
            headers: {
                'x-access-token': hash
            },
            method: 'GET'
        })
            .then((response) => (response.status !== 500) ? response.json() : reject())
            .then((responseJson) => {
                global.checkAuth(responseJson.status);
                console.log('closer_days list', responseJson);
                if (responseJson.status === "LIST_CLOSER_DAYS")
                    resolve(responseJson.list);
                else
                    reject(responseJson);
            })
            .catch((e) => {
                console.error(e.message);
                reject('APP_ERROR');
            });
    });
};

let add = function(closer_days) {
    return new Promise(function(resolve, reject) {
        const hash = localStorage.getItem('hash');

        fetch(global.route.closer_days, {
            headers: {
                'x-access-token': hash,
                'Content-Type': 'application/json'
            },
            method: 'PUT',
            body: JSON.stringify({
                operator_id: closer_days.operator_id,
                date_start: closer_days.date_start,
                date_end: closer_days.date_end
            })
        })
            .then((response) => (response.status !== 500) ? response.json() : reject())
            .then((responseJson) => {
                global.checkAuth(responseJson.status);
                console.log('closer_days add', responseJson);
                if (responseJson.status === "CLOSER_DAY_CREATED")
                    resolve(responseJson);
                else
                    reject(responseJson);
            })
            .catch((e) => {
                console.error(e.message);
                reject('APP_ERROR');
            });
    });
};

let edit = function(closer_days) {
    return new Promise(function(resolve, reject) {
        const hash = localStorage.getItem('hash');

        fetch(global.route.closer_days, {
            headers: {
                'x-access-token': hash,
                'Content-Type': 'application/json'
            },
            method: 'POST',
            body: JSON.stringify({
                id: closer_days.id,
                date_start: closer_days.date_start,
                date_end: closer_days.date_end
            })
        })
            .then((response) => (response.status !== 500) ? response.json() : reject())
            .then((responseJson) => {
                global.checkAuth(responseJson.status);
                console.log('closer_days edit', responseJson);
                if (responseJson.status === "CLOSER_DAY_EDITED")
                    resolve(responseJson);
                else
                    reject(responseJson);
            })
            .catch((e) => {
                console.error(e.message);
                reject('APP_ERROR');
            });
    });
};

let remove = function(id) {
    return new Promise(function(resolve, reject) {
        const hash = localStorage.getItem('hash');

        fetch(global.route.closer_days, {
            headers: {
                'x-access-token': hash,
                'Content-Type': 'application/json'
            },
            method: 'DELETE',
            body: JSON.stringify({
                id: id
            })
        })
            .then((response) => (response.status !== 500) ? response.json() : reject())
            .then((responseJson) => {
                global.checkAuth(responseJson.status);
                console.log('closer_days delete', responseJson);
                if (responseJson.status === "CLOSER_DAY_DELETED")
                    resolve(responseJson);
                else
                    reject(responseJson);
            })
            .catch((e) => {
                console.error(e.message);
                reject('APP_ERROR');
            });
    });
};

const CloseDayAPI = {
    list: list,
    add: add,
    edit: edit,
    remove: remove
};

module.exports = CloseDayAPI;
