import React, {useEffect, useState} from 'react';
import { Link } from 'react-router-dom';
import {
    Alert,
    Button,
    Card,
    CardBody,
    CardHeader,
    Col,
    Container,
    Form,
    Input,
    Label,
    Nav,
    NavItem,
    NavLink,
    Row,
    TabContent,
    TabPane
} from 'reactstrap';
import classnames from "classnames";
import Flatpickr from "react-flatpickr";
import TabProfile from "./tab/TabProfile";
import {UserAPI} from "api";
import {Loading} from "custom";
import TabCompany from "./tab/TabCompany";
import TabSecurity from "./tab/TabSecurity";
import TabNotification from "./tab/TabNotification";

//import images
// import progileBg from '../../assets/images/users/';
// import avatar1 from '../../assets/images/';

const Settings = () => {
    const [activeTab, setActiveTab] = useState("1");
    const [loading, setLoading] = useState(true);
    const [profile, setProfile] = useState(null);
    const [error, setError] = useState(false);

    useEffect(() => {
        loadProfile()
    }, [])

    const loadProfile = () => {
        UserAPI.getProfile().then((res) => {
            console.log(res)
            setProfile(res)
            setLoading(false)
        }).catch((err) => {
            console.error(err)
            setError(true)
        })
    }

    const tabChange = (tab) => {
        if (activeTab !== tab) setActiveTab(tab);
    };

    document.title="Impostazioni Profilo";

    if (error) {
        return <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Row style={{marginTop: 50}}>
                        <Col xxl={12}>
                            <Alert color="danger">Si è verificato un errore durante il recupero del profilo</Alert>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    }

    if (loading) {
        return <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Row style={{marginTop: 50}}>
                        <Col xxl={12}>
                            <Loading />
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Row style={{marginTop: 50}}>
                        <Col xxl={12}>
                            <Card className="mt-xxl-n5">
                                <CardHeader>
                                    <Nav className="nav-tabs-custom rounded card-header-tabs border-bottom-0"
                                        role="tablist">
                                        <NavItem>
                                            <NavLink to="#"
                                                     className={classnames({ active: activeTab === "1" })}
                                                     onClick={() => {
                                                         tabChange("1");
                                                     }}
                                                     type="button">
                                                <b><i className="mdi mdi-account-edit"></i> Utente</b>
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink to="#" disabled
                                                     className={classnames({ active: activeTab === "2" })}
                                                     onClick={() => {
                                                         tabChange("2");
                                                     }}
                                                     type="button">
                                                <b><i className="mdi mdi-store-edit"></i> Attività</b>
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink to="#"
                                                     disabled
                                                 className={classnames({ active: activeTab === "3" })}
                                                 onClick={() => {
                                                     tabChange("3");
                                                 }}
                                                 type="button">

                                                <b><i className="mdi mdi-earth-arrow-right"></i> Piano Attivo</b>
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink to="#" disabled
                                                className={classnames({ active: activeTab === "4" })}
                                                onClick={() => {
                                                    tabChange("4");
                                                }}
                                                type="button">
                                                <b><i className="mdi mdi-lock-alert" /> Sicurezza</b>
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink to="#"
                                                disabled
                                                className={classnames({ active: activeTab === "5" })}
                                                onClick={() => {
                                                    tabChange("5");
                                                }}
                                                type="button">
                                                <b><i className="mdi mdi-bell"></i> Notifiche</b>
                                            </NavLink>
                                        </NavItem>
                                    </Nav>
                                </CardHeader>
                                <CardBody className="p-4">
                                    <TabContent activeTab={activeTab}>
                                        <TabPane tabId="1">
                                            <TabProfile user={profile.user} />
                                        </TabPane>
                                        <TabPane tabId="2">
                                            {/*<TabCompany company={profile.company} />*/}
                                        </TabPane>
                                        <TabPane tabId="3">

                                        </TabPane>
                                        <TabPane tabId="4">
                                            {/*<TabSecurity sessions={profile.sessions} />*/}
                                        </TabPane>
                                        <TabPane tabId="5">
                                            {/*<TabNotification sessions={profile.notifications} />*/}
                                        </TabPane>
                                    </TabContent>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default Settings;
